import { Button, Drawer, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import useModel from "../../stores/model";
import AttributeTypeForm from "./AttributeTypeForm";
import useDrawer from "../../hooks/useDrawer";
import { useTranslation } from "react-i18next";

const { useForm } = Form;

const CreateAttribute = () => {
  const [form] = useForm();
  const { appendAttribute } = useModel();

  const submitHook = () => {
    const values = form.getFieldsValue();
    const { name, type, order } = values;
    const newAttribute = {
      type,
      order,
      name: [
        { value: name.zh, lang: "0" },
        { value: name.en, lang: "1" },
        { value: name.ru, lang: "2" },
      ],
      list: [],
    };

    appendAttribute(newAttribute);
  };

  const { visible, show, hide, onSubmit } = useDrawer(submitHook);

  const { t } = useTranslation();

  return (
    <div style={{ marginTop: 24 }}>
      <Button type="dashed" icon={<PlusOutlined />} onClick={show}>
        {t("model.addAttr.button")}
      </Button>
      <Drawer
        title={t("model.addAttr.title")}
        visible={visible}
        onClose={hide}
        destroyOnClose
        extra={
          <Button type="primary" onClick={onSubmit}>
            {t("model.addAttr.submit")}
          </Button>
        }
      >
        <AttributeTypeForm form={form} />
      </Drawer>
    </div>
  );
};

export default CreateAttribute;
