import regreisson, { DataPoint, Result } from "regression";
import { SpuPointsDataset } from "./stores/spuPoints";
import { DataSource, Dataset, CurveModel, ProccessedSpuData } from "./types";

const solveQuadratic = (a: number, b: number, c: number): number | void => {
  const delta = b ** 2 - 4 * a * c;
  if (delta < 0) return;
  if (delta === 0) return (-b + Math.sqrt(delta)) / (2 * a);

  const x1 = (-b + Math.sqrt(delta)) / (2 * a);
  const x2 = (-b - Math.sqrt(delta)) / (2 * a);
  const result = Math.max(x1, x2);
  if (result < 0) return;

  return result;
};

const shortestSolveQuartic = (
  a: number,
  b: number,
  c: number,
  d: number,
  e: number,
  center: number = 0
): number | void => {
  const df = (x: number) => a * x ** 4 + b * x ** 3 + c * x ** 2 + d * x + e;
  const dx = (x: number) => 4 * a * x ** 3 + 3 * b * x ** 2 + 2 * c * x + d;

  for (let x = center, xn = 0, i = 0; i < 100; x = xn, i += 1) {
    xn = x - df(x) / dx(x);

    if (Math.abs(x - xn) < 1e-9) return xn;
  }

  return;
};

export const findIntersectionPoint = (
  q: number,
  h: number,
  equation: Array<number>,
  type: number = 0,
  center: number = 0
): Array<number> | void => {
  const [a, b, c] = equation;
  let k, q1, h1;

  if (type !== 0) {
    k = Math.pow(h, 2) / Math.pow(q, 3);
    q1 = shortestSolveQuartic(
      a ** 2,
      2 * a * b - k,
      b * b + 2 * a * c,
      2 * b * c,
      c ** 2,
      center
    );
  } else {
    k = h / q ** 2;
    q1 = solveQuadratic(a - k, b, c);
  }

  if (!q1) return;
  h1 = q1 ** 2 * a + q1 * b + c;

  return [q1, h1];
};

export const genRegressionModal = (dataset: DataPoint[]): CurveModel => {
  const result = regreisson.polynomial(dataset, { order: 2, precision: 10 });
  const { predict, points } = result;
  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];

  return {
    ...result,
    dummyPoints: generateData(
      (x) => predict(x)[1],
      firstPoint[0],
      lastPoint[0],
      (lastPoint[0] - firstPoint[0]) / 100
    ),
  };
};

export const precision = (n: number, p: number): number => {
  // 当精度为 0 的时候，四舍五入取整
  if (p === 0) return Math.round(n);
  // 否则按照精度，在小数位四舍五入取整
  return Number(n.toFixed(p));
};

const ceil10 = (n: number): number => {
  if (n % 10 === 0) return n;
  return n - (n % 10) + 10;
};

export const calcYAxis = (
  Y: Array<number>,
  splitNumber: number,
  padding?: number
): [max: number, interval: number] => {
  let max = Math.max(...Y);

  if (padding) {
    max /= (splitNumber - padding) / splitNumber;
  }

  max = max < 10 ? Math.ceil(max) : ceil10(max);
  const interval = max / splitNumber;

  return [max, interval];
};

export const zip = (a: Array<any>, b: Array<any>): Array<any> =>
  a.map((e, i) => [e, b[i]]);

export const mapand = (
  lst: Array<any>,
  fn: (item: any) => boolean
): boolean => {
  if (lst.length === 0) return true;
  if (!fn(lst[0])) return false;

  return mapand(lst.slice(1), fn);
};

export const mapor = (lst: Array<any>, fn: (item: any) => boolean): boolean => {
  if (lst.length === 0) return false;
  if (fn(lst[0])) return true;

  return mapor(lst.slice(1), fn);
};

export const powerFormula = (point: DataPoint, eff: number): number => {
  const [q, h] = point;
  if (eff === 0) return 0;
  if (q <= 0 || h <= 0) return 0;
  return (q * h) / 367.2 / eff;
};

export const createQP = (
  QH: ReadonlyArray<DataPoint>,
  QEFF: ReadonlyArray<DataPoint>,
  sg: number = 1
): Array<DataPoint> => {
  return QH.map((point, i) => {
    if (i === 0) {
      return [QH[i][0], powerFormula(QH[1], QEFF[1][1] / 100) * 0.9 * sg];
    }
    return [point[0], powerFormula(point, QEFF[i][1] / 100) * sg];
  });
};

export const parseDataset = (dataset: Dataset): DataSource => {
  const QH = genRegressionModal(zip(dataset.Q, dataset.H));
  const QEFF = genRegressionModal(zip(dataset.Q, dataset.EFF));
  const QP = createQP(QH.points, QEFF.points);

  let result: DataSource = {
    name: dataset.name,
    type: dataset.type,
    frequency: dataset.frequency,
    diameter: dataset.diameter,
    speed: dataset.speed,
    range: dataset.range,
    QH,
    QP,
    QEFF,
  };

  if (dataset.NPSH)
    result.QNPSH = genRegressionModal(zip(dataset.Q, dataset.NPSH));
  if (dataset.diameter) result.diameter = dataset.diameter;

  return result;
};

export const generateData = (
  equation: (x: number) => number,
  start: number,
  end: number,
  step: number
): DataPoint[] => {
  const data: DataPoint[] = [];
  for (let x = start; x < end; x += step) {
    data.push([x, equation(x)]);
    if (x + step >= end) data.push([end, equation(end)]);
  }

  return data;
};

export const formatTextLine = (
  label: string,
  n: number,
  unit: string | undefined
) => {
  if (unit) return label + ": " + String(n) + " " + unit;
  return label + ": " + String(n);
};

export const rangeRight = (n: number, startAt: number = 0) => {
  const result = [];

  while (n > 0) {
    result.push(--n + startAt);
  }

  return result;
};

export const getSizeUnit = (size: number | undefined | null): "mm" | "%" => {
  return size ? "mm" : "%";
};

export const createK = (q: number, end: number, type: number = 0): number => {
  if (type !== 0) return Math.sqrt(q / end);
  return q / end;
};

export const stockMap = {
  all: undefined,
  outOfStock: 0,
  inStock: 1,
};

export const isSpuPointNotEmpty = (spu: ProccessedSpuData): boolean => {
  const ql = spu.Q.length;
  const hl = spu.H.length;
  const effl = spu.EFF.length;
  const npshl = spu.NPSH.length;

  if (npshl > 0) return Boolean(ql && hl && effl && npshl);
  return Boolean(ql && hl && effl);
};

export const parseSelected = (strList: string[] | null) => {
  if (strList === null) return [];
  return strList.map((str) => parseInt(str, 10));
};

export const createAuxiliaryData = (
  q: number,
  h: number,
  start: number,
  end: number,
  type: number
) => {
  let result, formula;
  if (type !== 0) {
    formula = (x: number) =>
      Math.sqrt((Math.pow(h, 2) / Math.pow(q, 3)) * x ** 3);
  } else {
    formula = (x: number) => (h / q ** 2) * x ** 2;
  }
  result = generateData(formula, start, end, 0.1);

  return result;
};

export const createQH2Data = (
  k: number,
  oldQHData: DataPoint[],
  type: number
): DataPoint[] => {
  let newQHData: DataPoint[];
  if (type !== 0) {
    newQHData = oldQHData.map((p) => [p[0] * k ** 2, p[1] * k ** 3]);
  } else {
    newQHData = oldQHData.map((p) => [p[0] * k, p[1] * k ** 2]);
  }

  return newQHData;
};

export const createQEFF2Data = (
  k: number,
  oldQEFFData: DataPoint[],
  type: number = 0
) => {
  let newQEFFData: DataPoint[];
  const cbrtK = Math.cbrt(k);
  if (type !== 0) {
    newQEFFData = oldQEFFData.map((p) => [p[0] * k ** 2, p[1] * cbrtK]);
  } else {
    newQEFFData = oldQEFFData.map((p) => [p[0] * k, p[1] * cbrtK]);
  }

  return newQEFFData;
};

export const createQNPSH2Data = (
  k: number,
  oldQNPSHData: DataPoint[],
  type: number
) => {
  if (!oldQNPSHData || oldQNPSHData.length === 0) return [];

  let newQNPSHData: DataPoint[];
  if (type !== 0) {
    newQNPSHData = oldQNPSHData.map((p) => [p[0] * k ** 2, p[1] * k ** 2]);
  } else {
    newQNPSHData = oldQNPSHData.map((p) => [p[0] * k, p[1] * k ** 2]);
  }

  return newQNPSHData;
};

// 创建新的叶轮直径
export const createDiameterData = (k: number, size: number, type: number) => {
  let diameter = 0;
  let diameterUnit = "mm";
  if (size) {
    if (type !== 0) {
      diameter = size * k;
    } else {
      diameter = size;
    }
  } else {
    diameterUnit = "%";
    if (type !== 0) {
      diameter = k * 100;
    } else {
      diameter = 100;
    }
  }

  return { diameter, diameterUnit };
};

// 创建新的转速
export const createSpeedData = (k: number, speed: number, type: number) => {
  if (type !== 0) {
    return speed;
  }
  return speed * k;
};

export const createFrequencyData = (
  k: number,
  frequency: number,
  type: number
) => {
  if (type === 0) return frequency * k;
  return frequency;
};

export const convertEFF1ToEFF2 = (
  oldQEFFObject: Result,
  oldQ: number,
  k: number
) => oldQEFFObject.predict(oldQ)[1] * Math.cbrt(k);

interface SpuPointsDataSourceBase {
  QH: CurveModel;
}

interface SpuPointsDataSource extends SpuPointsDataSourceBase {
  QP: DataPoint[];
  QEFF: CurveModel;
  QNPSH?: CurveModel;
}

export const parseSpuPointsDataset = (
  dataset: SpuPointsDataset
): SpuPointsDataSource => {
  const QH = genRegressionModal(zip(dataset.Q, dataset.H));
  const QEFF = genRegressionModal(zip(dataset.Q, dataset.EFF));
  const QP = createQP(QH.points, QEFF.points);
  const QNPSH =
    dataset.NPSH && genRegressionModal(zip(dataset.Q, dataset.NPSH));
  const result = { QH, QP, QEFF, QNPSH };

  return result;
};
