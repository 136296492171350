import ChartLayout from "../../../components/ChartLayout";
import SeriesParallelChart from "./SeriesParallelChart";
import SeriesParallelForm from "./SeriesParallelForm";
import useSpuPoints from "../../../stores/spuPoints";
import EmptyChart from "../components/EmptyChart";
import Loading from "../components/Loading";

const SeriesParallel = () => {
  const { name, dataset, performance, seriesParallel, loading } =
    useSpuPoints();
  const { fieldsValue } = performance;

  if (loading) return <Loading />;

  if (!name || !dataset) return <EmptyChart />;

  return (
    <ChartLayout
      chart={
        <SeriesParallelChart
          key={name}
          name={name}
          dataset={dataset}
          performance={fieldsValue}
          seriesParallel={seriesParallel}
        />
      }
      form={<SeriesParallelForm />}
    />
  );
};

export default SeriesParallel;
