import React from "react";
import { Avatar, Dropdown, Menu, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
// import loginService from "../services/login";
import useLoading from "../hooks/useLoading";

const UserMenu = () => {
  const { t } = useTranslation();
  const { auth, delAuth, isAdmin } = useAuth();
  const navigate = useNavigate();

  const handleSignout = async (key: string) => {
    // 登录菜单选择
    if (key === "signout") {
      const role = isAdmin ? "admin" : "user";
      // await loginService.signout();
      delAuth();

      if (role === "admin") {
        navigate("/admin");
      } else {
        navigate("/login");
      }
    }
  };

  const [onSignout, loading] = useLoading(handleSignout);
  const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

  const menu = (
    <Menu key="userinfo" onClick={({ key }) => onSignout(key)}>
      <Menu.Item key="userinfo">
        {`${t("layout.signedInAs")} ${auth?.username}`}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="signout">{t("layout.signout")}</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} arrow>
      <span style={{ cursor: "pointer" }}>
        <Spin
          spinning={loading}
          indicator={antIcon}
          style={{ paddingRight: "5px" }}
        />
        <Avatar>{auth?.username[0].toUpperCase()}</Avatar>
      </span>
    </Dropdown>
  );
};

export default UserMenu;
