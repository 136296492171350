import { TextData } from "../types";
import { formatTextLine } from "../utils";
import { RenderItem } from "../components/Chart";

export const renderLabel = (str?: string, offset: number[] = [30, -5]) => {
  if (str) {
    return {
      show: true,
      formatter: ({ dataIndex }: { dataIndex: number }) => {
        if (dataIndex === 0) return str;
        return "";
      },
      offset,
    };
  }
  return { show: false };
};

export const renderTypeLabel =
  (x: number, y: number, text: string) => (_params: any, _api: any) => ({
    type: "text",
    style: {
      text,
      lineHeight: 16,
      x,
      y,
    },
    z2: 200,
  });

export const genRenderItem = (
  type: "text" | "circle",
  x?: number,
  y?: number,
  data?: TextData[]
): RenderItem => {
  let text = "";

  if (!x || !y) {
    return () => {
      return { type };
    };
  }

  if (data) {
    data.forEach((d, i) => {
      if (d.value) {
        text += formatTextLine(d.label, d.value, d.unit) + "\n";
      }
    });
  }

  return (_param, api) => {
    if (type === "text") {
      return {
        type,
        style: {
          text,
          lineHeight: 16,
          x,
          y,
        },
        z2: 200,
      };
    }

    const [cx, cy] = api.coord([x, y]);
    return {
      type,
      shape: { cx, cy, r: 3 },
      style: { fill: "black" },
      z2: 200,
    };
  };
};

export const exportTemplate = {
  type: "png",
  pixelRatio: 2,
  backgroundColor: "#fff",
  excludeComponents: ["toolbox"],
};
