import { Button, Form, InputNumber, Radio } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import useSpuPoints from "../../../stores/spuPoints";

const { useForm } = Form;

const Unit = ({ name, style }: { name: string; style?: any }) => {
  return <span style={{ display: "block", width: 30, ...style }}>{name}</span>;
};

const PerformanceForm = () => {
  const [form] = useForm();
  const { t } = useTranslation();
  const { info, performance, setPerformanceValue, resetPerformance } =
    useSpuPoints();
  let [searchParams, setSearchParams] = useSearchParams();

  const { type } = info;
  const paramQ = Number(searchParams.get("q"));
  const paramH = Number(searchParams.get("h"));
  const { fieldsValue } = performance;
  const { quantity, head } = fieldsValue;

  useEffect(() => {
    if (quantity && head) return;

    if (paramQ && paramH) {
      form.setFieldsValue({
        quantity: paramQ,
        head: paramH,
      });
      setPerformanceValue({
        type: 0,
        quantity: paramQ,
        head: paramH,
        sg: 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: any) => {
    const { type, quantity, head, sg } = values;

    if (quantity === undefined || head === undefined) return;

    setPerformanceValue({
      type,
      quantity,
      head,
      sg: sg || 1,
    });

    if (paramQ && paramH) {
      setSearchParams({
        q: quantity,
        h: head,
      });
    }
  };

  const handleReset = () => {
    form.resetFields();
    resetPerformance();
    setSearchParams({});
  };

  return (
    <Form
      form={form}
      name="settings"
      layout="vertical"
      onReset={handleReset}
      onFinish={handleSubmit}
      initialValues={{ type: 0, sg: 1 }}
    >
      <Form.Item label={t("chart.formula")} name="type">
        <Radio.Group>
          <Radio.Button value={0}>Q² ÷ H</Radio.Button>
          <Radio.Button value={1} disabled={!type}>
            Q³ ÷ H²
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Q" name="quantity" required>
        <InputNumber
          style={{ width: "100%" }}
          precision={1}
          addonAfter={<Unit name="m³/h" />}
        />
      </Form.Item>
      <Form.Item label="H" name="head" required>
        <InputNumber
          style={{ width: "100%" }}
          precision={1}
          addonAfter={<Unit name="m" />}
        />
      </Form.Item>
      <Form.Item label={t("chart.sg")} name="sg">
        <InputNumber
          style={{ width: "100%" }}
          precision={2}
          min={0.2}
          max={5}
        />
      </Form.Item>
      <Form.Item noStyle>
        <Button type="primary" htmlType="submit">
          {t("chart.submit")}
        </Button>
        <Button type="ghost" htmlType="reset" style={{ marginLeft: 10 }}>
          {t("chart.clear")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PerformanceForm;
