import { Button, Drawer, Form } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useDrawer from "../../../hooks/useDrawer";
import useLoading from "../../../hooks/useLoading";
import useSpuPoints from "../../../stores/spuPoints";
import SpuForm from "../../SpuChart/components/SpuForm";

const { useForm } = Form;

const Edit = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { xs } = useBreakpoint();
  const { id } = useParams();
  const { info, rowDataSource, update } = useSpuPoints();

  const { visible, setVisible, show, hide } = useDrawer();

  const drawerWidth = xs ? 378 : 520;

  const handleEditOk = async () => {
    const values = await form.validateFields();
    await update(String(id), values);

    setVisible(false);
  };

  const [onSubmit, loading] = useLoading(handleEditOk);

  return (
    <>
      <Button type="primary" onClick={show}>
        {t("spuChart.tools.edit")}
      </Button>
      <Drawer
        title={t("spuChart.tools.edit")}
        onClose={hide}
        visible={visible}
        destroyOnClose
        width={drawerWidth}
        extra={
          <Button type="primary" onClick={onSubmit} loading={loading}>
            {t("spuChart.tools.editDrawer.submit")}
          </Button>
        }
      >
        <SpuForm
          form={form}
          layout="vertical"
          initialValues={{
            freq: info.frequency,
            start: info.range.start,
            end: info.range.end,
            size: info.diameter,
            speed: info.speed,
            formula: info.type,
            dataset: rowDataSource,
          }}
        />
      </Drawer>
    </>
  );
};

export default Edit;
