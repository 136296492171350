import {
  Card,
  ConfigProvider,
  Table,
  PageHeader,
  Space,
  Form,
  Input,
  Button,
  Dropdown,
  Menu,
  Upload,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { langMap } from "../../i18n";
import BaseLayout from "../../components/BaseLayout";
import { Key, useEffect, useState } from "react";
import useLoading from "../../hooks/useLoading";
import { DownOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../libs/axios";
import useAuth from "../../hooks/useAuth";
import modelServices from "../../services/model";
import { Link } from "react-router-dom";

const UploadDropdown = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [uploading, setUploading] = useState(false);

  if (!auth) return <></>;

  const props = {
    name: "file",
    action: `${BASE_URL}/spus/import/information`,
    headers: { authorization: `Bearer ${auth.token}` },
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onChange: (info: any) => {
      if (info.file.status !== "uploading") {
        setUploading(false);
      }
      if (info.file.status === "uploading" && !uploading) {
        setUploading(true);
      }
      if (info.file.status === "done") {
        message.success(
          t("spuList.extra.upload.success", {
            import_count: info.file.response.response.import_count,
          })
        );
      }
      if (info.file.status === "error") {
        message.error(t("spuList.extra.upload.error"));
      }
    },
    showUploadList: false,
    multiple: false,
  };

  const menu = (
    <Menu>
      <Menu.Item key="size">
        <Upload {...props} data={{ type: 1 }}>
          {t("modelList.upload.size")}
        </Upload>
      </Menu.Item>
      <Menu.Item key="attribute">
        <Upload {...props} data={{ type: 2 }}>
          {t("modelList.upload.attribute")}
        </Upload>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button type="default" loading={uploading}>
        <Space>
          {t("modelList.upload.button")} <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

const ModelList = () => {
  const [dataSource, setDataSource] = useState([]);
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchRef] = Form.useForm();

  let query: string = searchParams.get("query") || "";
  let page: number = Number(searchParams.get("page")) || 1;
  let pageSize: number = Number(searchParams.get("pageSize")) || 20;
  const [total, setTotal] = useState<number>(0);

  const handleSubmit = (values: { query: string }) => {
    const { query } = values;

    setSearchParams({ query, page: "1", pageSize: "20" });
  };

  const handleReset = () => {
    searchRef.resetFields();
    setSearchParams({});
  };

  const handleFormChange = () => {
    searchRef.setFieldsValue({ query });
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setDataSource([]);
    setSearchParams({
      query,
      page: String(page),
      pageSize: pageSize ? String(pageSize) : "20",
    });
  };

  const handleNew = () => navigate("/models/new");

  const getModelList = async () => {
    const result = await modelServices.getList({
      q: query,
      page: page,
      limit: pageSize,
    });
    const { totalCount, models } = result.data.response;

    setTotal(totalCount);
    setDataSource(
      models.map((item: any) => ({
        key: item.id,
        erpId: item.erp_id,
        name: item.spu_model_name,
      }))
    );
  };

  const [onPageChange, loading] = useLoading(getModelList);

  useEffect(() => {
    handleFormChange();
    onPageChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, pageSize]);

  const handleDelete = async (key: Key) => {
    try {
      await modelServices.deleteModel(key as string);
      onPageChange();
    } catch (error) {
      message.warning(t("modelList.warning.includeChildren"));
    }
  };

  const columns = [
    {
      key: "key",
      title: "id",
      dataIndex: "key",
      width: 100,
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      ellipsis: true,
      width: 200,
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      width: 100,
      fixed: "right" as const,
      render: (_: any, record: { key: Key }) => {
        const menu = (
          <Menu>
            <Menu.Item key="edit">
              <Link to={`/models/${record.key}`}>
                {t("modelList.action.edit")}
              </Link>
            </Menu.Item>
            <Menu.Item key="delete" onClick={() => handleDelete(record.key)}>
              {t("modelList.action.delete")}
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu}>
            <Button type="text" icon={<UnorderedListOutlined />}>
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <BaseLayout title={t("route.modelList")}>
      <PageHeader
        title={t("route.modelList")}
        style={{ padding: "16px 0" }}
        extra={[
          <UploadDropdown key="upload" />,
          <Button key="new" type="primary" onClick={handleNew}>
            {t("modelList.new.title")}
          </Button>,
        ]}
      />
      <Card bodyStyle={{ paddingBottom: 0 }}>
        <Form layout="inline" onFinish={handleSubmit} form={searchRef}>
          <Form.Item name="query" style={{ paddingBottom: 24 }}>
            <Input placeholder={t("modelList.search.name.placeholder")} />
          </Form.Item>
          <Form.Item style={{ paddingBottom: 24 }}>
            <Space>
              <Button type="primary" htmlType="submit" loading={false}>
                {t("modelList.search.submit")}
              </Button>
              <Button type="default" onClick={handleReset}>
                {t("modelList.search.reset")}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Card style={{ marginTop: 24 }}>
        <ConfigProvider locale={langMap(i18n.language)}>
          <Table
            columns={columns}
            dataSource={dataSource}
            bordered={false}
            scroll={{ x: 325 }}
            loading={loading}
            pagination={{
              current: page,
              pageSize,
              total,
              onChange: handlePageChange,
            }}
          />
        </ConfigProvider>
      </Card>
    </BaseLayout>
  );
};

export default ModelList;
