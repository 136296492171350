import React from "react";
import create from "zustand";
import spuServices from "../services/spu";

export interface SpuPointsDataset {
  Q: Array<number>;
  H: Array<number>;
  EFF: Array<number>;
  NPSH?: Array<number>;
}

export interface PerformanceFormField {
  type: 0;
  quantity?: number;
  head?: number;
  sg?: number;
}

export interface PerformanceFormResult {
  diameter: number;
  speed: number;
  frequency: number;
  power: number;
  npsh?: number;
  eff: number;
}

export interface PerformanceForm {
  fieldsValue: PerformanceFormField;
  fieldsResult?: PerformanceFormResult;
}

export interface MultiSpeedForm {
  speedItems: number[];
}

export interface PerformanceSpeedForm {
  fieldsValue: PerformanceFormField;
  fieldsResult?: PerformanceFormResult;
  speedItems: number[];
}

export interface SeriesParallelForm {
  type: "series" | "parallel";
  quantity: number;
}

export type CellDataType = number | null | undefined;

export interface SpuPointsInfo {
  range: {
    start: number;
    end: number;
  };
  type: boolean;
  diameter: number;
  frequency: number;
  speed: number;
}

export interface SpuPointsDataSource {
  key: React.Key;
  q: number;
  h: number;
  eff: number;
  npsh: CellDataType;
}

interface SpuPoints {
  id: number;
  name: string;
  info: SpuPointsInfo;
  dataset?: SpuPointsDataset;
  dataSource?: SpuPointsDataSource[];
  rowDataSource?: SpuPointsDataSource[];
  performance: PerformanceForm;
  performanceSpeed: PerformanceSpeedForm;
  multiSpeed: MultiSpeedForm;
  seriesParallel: SeriesParallelForm;
  loading: boolean;
}

interface SpuPointsState extends SpuPoints {
  create: (id: string, values: any) => void;
  fetch: (id: string) => void;
  update: (id: string, values: any) => void;
  delete: (id: string) => void;
  setDataset: (dataset: SpuPointsDataset) => void;
  setDataSource: (dataSource: SpuPointsDataSource[]) => void;
  setPerformanceValue: (value: PerformanceFormField) => void;
  setPerformanceResult: (result: PerformanceFormResult) => void;
  resetPerformance: () => void;
  setMultiSpeed: (value: MultiSpeedForm) => void;
  resetMultiSpeed: () => void;
  setPerformanceSpeedValue: (value: PerformanceFormField) => void;
  setPerformanceSpeedResult: (value: PerformanceFormResult) => void;
  setPerformanceSpeedItems: (value: number[]) => void;
  resetPerformanceSpeed: () => void;
  setSeriesParallel: (value: SeriesParallelForm) => void;
  resetSeriesParallel: () => void;
}

export const datasetToDataSource = (
  dataset: SpuPointsDataset
): SpuPointsDataSource[] => {
  return dataset.Q.map(
    (q: number, i: number): SpuPointsDataSource => ({
      key: String(i),
      q,
      h: dataset.H[i],
      eff: dataset.EFF[i],
      npsh: dataset.NPSH ? dataset.NPSH[i] : undefined,
    })
  );
};

export const dataSourceToDataset = (
  dataSource: SpuPointsDataSource[]
): SpuPointsDataset => {
  const reducer = (
    accu: SpuPointsDataset,
    curr: SpuPointsDataSource
  ): SpuPointsDataset => {
    const { q, h, eff, npsh } = curr;
    const { Q, H, EFF, NPSH } = accu;

    return {
      Q: [...Q, q],
      H: [...H, h],
      EFF: [...EFF, eff],
      NPSH: npsh && NPSH ? [...NPSH, npsh] : [],
    };
  };

  return dataSource.reduce(reducer, {
    Q: [],
    H: [],
    EFF: [],
    NPSH: [],
  });
};

const parseSpuPoints = (data: any) => {
  const info: SpuPointsInfo = {
    range: {
      start: data.valid_start,
      end: data.valid_end,
    },
    type: data.use_formula,
    diameter: data.size,
    frequency: data.freq,
    speed: data.speed,
  };
  const dataset: SpuPointsDataset = {
    Q: data.q_coordinates,
    H: data.h_coordinates,
    EFF: data.eff_coordinates,
    NPSH: data.npsh_coordinates,
  };
  const dataSource: SpuPointsDataSource[] = datasetToDataSource(dataset);

  return {
    id: data.id,
    name: data.spu,
    info,
    dataset,
    dataSource,
    rowDataSource: dataSource,
    loading: false,
  };
};

const initialPerformance: PerformanceForm = {
  fieldsValue: { type: 0 },
};

const initialMultiSpeed: MultiSpeedForm = {
  speedItems: [],
};

const initialPerformanceSpeed: PerformanceSpeedForm = {
  fieldsValue: { type: 0 },
  speedItems: [],
};

const initialSeriesParallel: SeriesParallelForm = {
  type: "series",
  quantity: 1,
};

const initialState: SpuPoints = {
  id: 0,
  name: "",
  info: {
    range: {
      start: 0,
      end: 0,
    },
    type: false,
    diameter: 0,
    frequency: 0,
    speed: 0,
  },
  dataset: undefined,
  dataSource: undefined,
  rowDataSource: undefined,
  performance: initialPerformance,
  multiSpeed: initialMultiSpeed,
  performanceSpeed: initialPerformanceSpeed,
  seriesParallel: initialSeriesParallel,
  loading: false,
};

const useSpuPoints = create<SpuPointsState>((set, get) => ({
  ...initialState,
  create: async (id: string, values: any) => {
    const { dataset: newRowDataSource } = values;
    const newDataset = dataSourceToDataset(newRowDataSource);
    const data = {
      spu_id: Number(id),
      valid_start: values.start,
      valid_end: values.end,
      use_formula: values.formula,
      q_coordinates: newDataset.Q,
      h_coordinates: newDataset.H,
      eff_coordinates: newDataset.EFF,
      npsh_coordinates: newDataset.NPSH ? newDataset.NPSH : [],
      size: values.size,
      freq: values.freq,
      speed: values.speed,
    };
    // 创建数据
    const res = await spuServices.postPoints(id, data);
    const resData = res.data.response;
    const { spu } = resData;
    // 更新 info/dataSource/rowDataSource/dataset
    const newInfo = {
      range: {
        start: data.valid_start,
        end: data.valid_end,
      },
      type: data.use_formula,
      diameter: data.size,
      frequency: data.freq,
      speed: data.speed,
    };
    set({
      name: spu,
      info: newInfo,
      rowDataSource: newRowDataSource,
      dataSource: newRowDataSource,
      dataset: newDataset,
    });
  },
  fetch: async (id: string) => {
    try {
      set({ loading: true });
      const res = await spuServices.getPoints(id);
      const data = res.data.response;
      const result = parseSpuPoints(data);

      set({ ...initialState, ...result });
    } catch (e) {
      console.error(e);
      set({ ...initialState });
    } finally {
      set({ loading: false });
    }
  },
  update: async (id: string, values: any) => {
    try {
      const { dataset: newRowDataSource } = values;
      const newDataset = dataSourceToDataset(newRowDataSource);
      const data = {
        spu_id: Number(id),
        valid_start: values.start,
        valid_end: values.end,
        use_formula: values.formula,
        q_coordinates: newDataset.Q,
        h_coordinates: newDataset.H,
        eff_coordinates: newDataset.EFF,
        npsh_coordinates: newDataset.NPSH ? newDataset.NPSH : [],
        size: values.size,
        freq: values.freq,
        speed: values.speed,
      };
      // 上传数据
      await spuServices.putPoints(id, data);
      // 更新 info/dataSource/rowDataSource/dataset
      const newInfo = {
        range: {
          start: data.valid_start,
          end: data.valid_end,
        },
        type: data.use_formula,
        diameter: data.size,
        frequency: data.freq,
        speed: data.speed,
      };
      set({
        info: newInfo,
        rowDataSource: newRowDataSource,
        dataSource: newRowDataSource,
        dataset: newDataset,
      });
    } catch (e) {
      console.error(e);
    }
  },
  delete: async (id: string) => {
    try {
      await spuServices.deletePoints(id);
      set({ ...initialState });
    } catch (e) {
      console.error(e);
    }
  },
  setDataset: (dataset: SpuPointsDataset) => set({ dataset }),
  setDataSource: (dataSource: SpuPointsDataSource[]) => {
    const dataset = dataSourceToDataset(dataSource);
    set({ dataSource, dataset });
  },
  setPerformanceValue: (value: PerformanceFormField) =>
    set({ performance: { fieldsValue: value } }),
  setPerformanceResult: (result: PerformanceFormResult) =>
    set(({ performance }) => ({
      performance: { ...performance, fieldsResult: result },
    })),
  resetPerformance: () => set({ performance: initialPerformance }),
  setMultiSpeed: (value: MultiSpeedForm) => set({ multiSpeed: value }),
  resetMultiSpeed: () => set({ multiSpeed: initialMultiSpeed }),
  setPerformanceSpeedValue: (value: PerformanceFormField) =>
    set(({ performanceSpeed }) => ({
      performanceSpeed: { ...performanceSpeed, fieldsValue: value },
    })),
  setPerformanceSpeedResult: (result: PerformanceFormResult) =>
    set(({ performanceSpeed }) => ({
      performanceSpeed: { ...performanceSpeed, fieldsResult: result },
    })),
  setPerformanceSpeedItems: (value: number[]) =>
    set(({ performanceSpeed }) => ({
      performanceSpeed: { ...performanceSpeed, speedItems: value },
    })),
  resetPerformanceSpeed: () =>
    set({ performanceSpeed: initialPerformanceSpeed }),
  setSeriesParallel: (value: SeriesParallelForm) =>
    set({ seriesParallel: value }),
  resetSeriesParallel: () => set({ seriesParallel: initialSeriesParallel }),
}));

export default useSpuPoints;
