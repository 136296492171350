import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import SpuList from "./pages/SpuList/index";
import SpuChart from "./pages/SpuChart/index";
import Spu from "./pages/Spu/index";
import SpuSelection from "./pages/SpuSelection";
import Login from "./pages/Login";
import Model from "./pages/Model/index";
import useAuth from "./hooks/useAuth";
import FileManager from "./pages/FileManager";
import ModelList from "./pages/ModelList";
import CreateModel from "./pages/Model/Create";
import NotFound from "./pages/NotFound";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let { auth } = useAuth();
  let location = useLocation();

  if (!auth?.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/selection" />} />
        <Route
          path="/spus-old/:id"
          element={
            <RequireAuth>
              <SpuChart />
            </RequireAuth>
          }
        />
        <Route
          path="/spus/:id/:tab"
          element={
            <RequireAuth>
              <Spu />
            </RequireAuth>
          }
        />
        <Route
          path="/spus/:id"
          element={
            <RequireAuth>
              <Spu />
            </RequireAuth>
          }
        />
        <Route
          path="/models/:id"
          element={
            <RequireAuth>
              <Model />
            </RequireAuth>
          }
        />
        <Route
          path="/models/new"
          element={
            <RequireAuth>
              <CreateModel />
            </RequireAuth>
          }
        />
        <Route
          path="/spus"
          element={
            <RequireAuth>
              <SpuList />
            </RequireAuth>
          }
        />
        <Route
          path="/selection"
          element={
            <RequireAuth>
              <SpuSelection />
            </RequireAuth>
          }
        />
        <Route
          path="/models"
          element={
            <RequireAuth>
              <ModelList />
            </RequireAuth>
          }
        />
        <Route
          path="/filemanager"
          element={
            <RequireAuth>
              <FileManager />
            </RequireAuth>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Login isAdmin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
