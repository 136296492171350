import { Button, Modal, Input, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import useField from "../../../hooks/useField";
import useModal from "../../../hooks/useModal";
import useStorage from "../../../stores/storage";
import { useTranslation } from "react-i18next";

const Rename = () => {
  const {
    fileList,
    fetchCurrentDirectory,
    fetchFolderTree,
    updateName,
    selected,
  } = useStorage();

  const { t } = useTranslation();
  const { value, setValue, onChange } = useField("");
  const inputProps = { value, onChange };

  const showHook = () => {
    const name = fileList.find((file) => file.id === selected[0])?.name;
    if (name) setValue(name);
  };

  const submitHook = async () => {
    await updateName(selected[0], value);
    await fetchCurrentDirectory();
    await fetchFolderTree();
    setValue("");
  };

  const cancelHook = () => setValue("");

  const { onOk, onCancel, confirmLoading, visible, show, okText, cancelText } =
    useModal(submitHook, cancelHook, showHook);
  const modalProps = {
    onOk,
    onCancel,
    confirmLoading,
    visible,
    okText,
    cancelText,
  };

  return (
    <>
      <Tooltip title={t("fileManager.tool.rename.title")}>
        <Button type="text" icon={<EditOutlined />} onClick={show} />
      </Tooltip>
      <Modal
        title={t("fileManager.tool.rename.title")}
        centered
        {...modalProps}
      >
        <Input {...inputProps} />
      </Modal>
    </>
  );
};

export default Rename;
