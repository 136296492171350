import axios from "../libs/axios";

const getCurrentDirectory = async (pid?: number) =>
  await axios.get("/folder/select", { params: { type: 1, pid } });

const getFolderTree = async () =>
  await axios.get("/folder/select", { params: { type: 3 } });

const search = async (keyword: string) =>
  await axios.get("/folder/select", { params: { type: 2, key: keyword } });

const createDirectory = async (name: string, pid: number) =>
  await axios.post("/folder/insert", { name, pid });

const deleteObjects = async (checkList: number[]) =>
  await axios.delete("/folder/delete", { data: { checkList } });

const moveObjects = async (checkList: number[], newId: number) =>
  await axios.put("/folder/update", { type: 2, checkList, newId });

const updateName = async (checkId: number, newName: string) =>
  await axios.put("/folder/update", { type: 3, checkId, newName });

const storageServices = {
  getCurrentDirectory,
  getFolderTree,
  search,
  createDirectory,
  deleteObjects,
  moveObjects,
  updateName,
};

export default storageServices;
