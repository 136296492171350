import { Row, Col } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BlankLayout from "../components/BlankLayout";
import LoginDialog from "../components/LoginDialog";
import useLoading from "../hooks/useLoading";
import useAuth from "../hooks/useAuth";
import useTitle from "../hooks/useTitle";
import loginService from "../services/login";
import "./Login.css";
import i18n from "../i18n";

const Login = ({ isAdmin = false }) => {
  const navigate = useNavigate();
  const { auth, updateAuth } = useAuth();
  const { t } = useTranslation();
  useTitle(isAdmin ? t("route.admin") : t("route.login"));

  const handleLoginSubmit = async (values: any) => {
    const { account, password } = values;
    if (!account || !password) return;

    // 更新登录数据
    const result = isAdmin
      ? await loginService.loginAsAdmin({ account, password })
      : await loginService.login({ account, password });
    updateAuth({
      ...result.data.response,
      username: account,
    });

    // 当本地没有保存语言设置时，使用返回的默认语言设置本地语言
    const currLang = window.localStorage.getItem("i18nextLng");
    if (!currLang) i18n.changeLanguage(result.data.response.language);
  };

  const [onLogin, loading] = useLoading(handleLoginSubmit);

  useEffect(() => {
    if (auth !== undefined) navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <BlankLayout id="login-layout">
      <Row justify="center">
        <Col
          xxl={9}
          xl={11}
          lg={0}
          md={0}
          sm={0}
          xs={0}
          id="login-left-background"
          className="fill-screen"
        ></Col>
        <Col
          xxl={{ span: 9, offset: 1 }}
          xl={{ span: 11, offset: 1 }}
          lg={16}
          md={24}
          sm={24}
          xs={24}
          id="login-right-background"
          className="fill-screen"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoginDialog onLogin={onLogin} loading={loading} />
        </Col>
      </Row>
    </BlankLayout>
  );
};

export default Login;
