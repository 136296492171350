import { Divider } from "antd";
import {
  FileTextTwoTone,
  FileImageTwoTone,
  FileWordTwoTone,
} from "@ant-design/icons";
import CreateText from "./CreateText";
import CreateImage from "./CreateImage";
import EditAttribute from "./EditAttribute";
import EditSubAttribute from "./EditSubAttribute";
import { Name } from "../../stores/model";

interface AttributeChunkProps {
  id: number;
  name: Name[];
  rowIndex: number;
  type: 0 | 1;
  order: number;
  items: any[];
  isLast: boolean;
}

const AttributeChunk = ({
  id,
  name,
  rowIndex,
  type,
  order,
  items,
  isLast,
}: AttributeChunkProps) => {
  const icon = () => {
    if (type === 0) return <FileTextTwoTone style={{ fontSize: 18 }} />;
    if (type === 1) return <FileImageTwoTone style={{ fontSize: 18 }} />;
    return <FileWordTwoTone style={{ fontSize: 18 }} />;
  };

  return (
    <div style={{ marginBottom: 0 }}>
      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <span style={{ marginRight: 10, padding: "auto" }}>{icon()}</span>
        <EditAttribute rowIndex={rowIndex} data={{ id, type, order, name }} />
        <span style={{ marginRight: 10 }}>:</span>
        {items.map((item: any, index: number) => (
          <EditSubAttribute
            key={index}
            rowIndex={rowIndex}
            colIndex={index}
            type={type}
            data={item}
          />
        ))}
        {type === 0 ? (
          <CreateText rowIndex={rowIndex} />
        ) : (
          <CreateImage rowIndex={rowIndex} />
        )}
      </div>
      {!isLast && <Divider style={{ marginTop: 24 }} />}
    </div>
  );
};

export default AttributeChunk;
