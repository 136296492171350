import { ConfigProvider, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useLoading from "../../../hooks/useLoading";
import { langMap } from "../../../i18n";
import spuServices from "../../../services/spu";
import {
  // Interval,
  SpuFullData,
  Stock,
  Range,
  ProccessedSpuData,
} from "../../../types";
import {
  stockMap,
  genRegressionModal,
  zip,
  createQP,
  createK,
  findIntersectionPoint,
  powerFormula,
  precision,
  isSpuPointNotEmpty,
} from "../../../utils";
import { Result } from "regression";
// import Rectangle from "../../../libs/rectangle";

interface SelectionResultProps {
  q: number;
  h: number;
  toleranceStart: number;
  toleranceEnd: number;
  stock: Stock;
  selected: number[];
}

const isRangeOverlap = (range: Range, aux: Range) => {
  const mostLeft = Math.min(range.min, range.max, aux.min, aux.max);
  const mostRight = Math.max(range.min, range.max, aux.min, aux.max);
  const rangeLength = range.max - range.min;
  const auxLength = aux.max - aux.min;

  return mostRight - mostLeft <= rangeLength + auxLength;
};

// const findStartPoint = (loq: number[], loh: number[], start: number) => {
//   const i = loq.findIndex((x) => x >= start);
//   return {
//     x: loq[i],
//     y: loh[i],
//   };
// };

// const findEndPoint = (loq: number[], loh: number[], end: number) => {
//   let i = loq.findIndex((x) => x > end);
//   if (i - 1 < 0) i = loq.length;

//   return {
//     x: loq[i - 1],
//     y: loh[i - 1],
//   };
// };

// const isRectangleOverlap = (q: number, h: number, curveInterval: Interval) => {
//   const auxInterval = {
//     p: { x: q, y: h },
//     q: { x: q * 1.2, y: (h / q ** 2) * (q * 1.2) ** 2 },
//   };
//   const rect1 = new Rectangle(auxInterval.p, auxInterval.q);
//   const rect2 = new Rectangle(curveInterval.p, curveInterval.q);

//   return rect1.overlapWith(rect2);
// };

const isPolyIntersected = (
  q: number,
  h: number,
  toleranceStart: number,
  toleranceEnd: number,
  spuEquation: number[],
  start: number,
  end: number
) => {
  const auxEquation = [h / q ** 2, 0, 0];
  const [a, b, c] = auxEquation.map((e, i) => e - spuEquation[i]);
  const delta = b ** 2 - 4 * a * c;
  if (delta < 0) return false;

  const x1 = (-b + Math.sqrt(delta)) / (2 * a);
  const x2 = (-b - Math.sqrt(delta)) / (2 * a);
  const x = Math.max(x1, x2);
  const r1 = { min: start, max: end };
  const r2 = { min: q * toleranceStart, max: q * toleranceEnd };

  return x >= r1.min && x <= r1.max && x >= r2.min && x <= r2.max;
};

const convertEFF1ToEFF2 = (oldQEFFObject: Result, oldQ: number, k: number) =>
  oldQEFFObject.predict(oldQ)[1] * Math.cbrt(k);

const createWorkPoint = (q: number, h: number, spu: ProccessedSpuData) => {
  // 1. find intersection point
  if (!spu.QH || !spu.QEFF) return spu;
  const intersectionPoint = findIntersectionPoint(q, h, spu.QH?.equation);
  // 2. create the value of k
  if (!intersectionPoint) return spu;
  const k = createK(q, intersectionPoint[0]);
  // 3. use k to calculate eff/p/npsh?
  const eff = convertEFF1ToEFF2(spu.QEFF, intersectionPoint[0], k);
  const p = powerFormula([q, h], eff / 100);
  // 4. return new object
  if (spu.QNPSH) {
    const npsh = spu.QNPSH.predict(q)[1];

    return {
      ...spu,
      q,
      h,
      eff: precision(eff, 2),
      p: precision(p, 2),
      npsh: precision(npsh, 2),
    };
  }
  return {
    ...spu,
    q,
    h,
    eff: precision(eff, 2),
    p: precision(p, 2),
  };
};

const selectionProccess = (
  q: number,
  h: number,
  toleranceStart: number,
  toleranceEnd: number,
  results: SpuFullData[]
) => {
  if (!results) return [];
  if (results.length === 0) return results;

  let newResults: ProccessedSpuData[] = results;
  // 0. 过滤掉没有数据点的Spu
  newResults = newResults.filter(isSpuPointNotEmpty);
  // 1. 根据区间长度过滤一部分数据
  newResults = newResults.filter((spu: any) =>
    isRangeOverlap(
      { min: spu.start, max: spu.end },
      { min: q * (toleranceStart / 100), max: q * (toleranceEnd / 100) }
    )
  );
  // console.log("after range overlap", newResults);
  // 2. 获取过滤后的完整数据
  // 3. 根据矩形区域过滤一部分数据
  // newResults = newResults.filter((spu) =>
  //   isRectangleOverlap(q, h, {
  //     p: findStartPoint(spu.Q as number[], spu.H as number[], spu.start),
  //     q: findEndPoint(spu.Q as number[], spu.H as number[], spu.end),
  //   })
  // );
  // console.log("after rectangle overlap", newResults);
  // 4. 对剩余的数据进行线性回归
  // 5. 使用交点公式进行最后的过滤
  newResults = newResults
    .map((spu) => {
      const QH = genRegressionModal(zip(spu.Q as any, spu.H as any));
      const QEFF = genRegressionModal(zip(spu.Q as any, spu.EFF as any));
      const QP = createQP(QH.points, QEFF.points);

      if (spu.NPSH?.length) {
        return {
          ...spu,
          QH,
          QEFF,
          QP,
          QNPSH: genRegressionModal(zip(spu.Q as any, spu.NPSH as any)),
        };
      }

      return {
        ...spu,
        QH,
        QEFF,
        QP,
      };
    })
    .filter((spu) =>
      isPolyIntersected(
        q,
        h,
        toleranceStart / 100,
        toleranceEnd / 100,
        spu.QH.equation,
        spu.start,
        spu.end
      )
    )
    .map((spu) => createWorkPoint(q, h, spu));
  // console.log("final results:", newResults);

  return newResults;
};

const parseFullSpus = (spus: any): SpuFullData[] => {
  return spus.map((item: any) => ({
    id: item.id,
    spu: item.spu,
    start: item.valid_start || item.start,
    end: item.valid_end || item.end,
    Q: item.q_coordinates,
    H: item.h_coordinates,
    EFF: item.eff_coordinates,
    NPSH: item.npsh_coordinates,
  }));
};

const SelectionResult = ({
  q,
  h,
  toleranceStart,
  toleranceEnd,
  stock,
  selected,
}: SelectionResultProps) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      width: 100,
    },
    {
      title: t("spuList.columns.name"),
      dataIndex: "spu",
      key: "spu",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Q",
      dataIndex: "q",
      key: "q",
      width: 100,
      ellipsis: true,
    },
    {
      title: "H",
      dataIndex: "h",
      key: "h",
      width: 100,
      ellipsis: true,
    },
    {
      title: "EFF",
      dataIndex: "eff",
      key: "eff",
      width: 100,
      ellipsis: true,
    },
    {
      title: "P",
      dataIndex: "p",
      key: "p",
      width: 100,
      ellipsis: true,
    },
    {
      title: "NPSH",
      dataIndex: "npsh",
      key: "npsh",
      width: 100,
      ellipsis: true,
    },
    {
      key: "action",
      fixed: "right" as const,
      width: 80,
      dataIndex: "action",
      title: t("spuList.columns.action"),
      render: (text: string, record: any, index: number) => {
        const { id, q, h } = record;

        if (q && h) {
          return (
            <Link to={`/spus/${id}?q=${q}&h=${h}`}>
              {t("spuSelection.details")}
            </Link>
          );
        }

        return <Link to={`/spus/${id}`}>{t("spuSelection.details")}</Link>;
      },
    },
  ];

  const [dataSource, setDataSource] = useState<SpuFullData[]>([]);

  const getSpuList = async () => {
    const res = await spuServices.getSelection({
      page: 1,
      limit: 300,
      full: 0,
      q,
      stock: stockMap[stock],
      cat: selected.join(",") || undefined,
    });
    const { spus } = res.data.response;
    const processedSpus = parseFullSpus(spus);
    const filteredSpus = selectionProccess(
      q,
      h,
      toleranceStart,
      toleranceEnd,
      processedSpus
    );

    setDataSource(filteredSpus);
  };

  const [onChange, loading] = useLoading(getSpuList);
  useEffect(() => {
    onChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, h, toleranceStart, toleranceEnd, stock, JSON.stringify(selected)]);

  return (
    <ConfigProvider locale={langMap(i18n.language)}>
      <Table
        columns={columns}
        bordered={false}
        scroll={{ x: 325 }}
        dataSource={dataSource}
        pagination={false}
        rowKey="id"
        loading={loading}
      />
    </ConfigProvider>
  );
};

export default SelectionResult;
