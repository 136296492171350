import { Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import InputName from "../../components/InputName";

interface Props {
  form: any;
  initialValues?: {
    name: {
      zh: string;
      en: string;
      ru: string;
    };
    order: number;
  };
}

const AttributeTextForm = ({ form, initialValues }: Props) => {
  const { t } = useTranslation();

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={
        initialValues || {
          name: {
            zh: "",
            en: "",
            ru: "",
          },
          order: 100,
        }
      }
    >
      <Form.Item label={t("model.textAttrForm.name")} name="name" required>
        <InputName />
      </Form.Item>
      <Form.Item label={t("model.textAttrForm.sort")} name="order" required>
        <InputNumber min={0} precision={0} />
      </Form.Item>
    </Form>
  );
};

export default AttributeTextForm;
