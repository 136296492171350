import { Form, Button, Space, Drawer, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import AttributeTypeForm from "./AttributeTypeForm";
import useModel, { AttributeState } from "../../stores/model";
import useDrawer from "../../hooks/useDrawer";
import { Name } from "../../stores/model";
import { nameLangMap } from "../../i18n";
import { useTranslation } from "react-i18next";

const { useForm } = Form;

const childrenCount = (attributes: AttributeState[], index: number): number => {
  const currAttr = attributes.find((attribute, i) => i === index);

  return currAttr ? currAttr.list.length : 0;
};

const EditAttribute = ({
  rowIndex,
  data,
}: {
  rowIndex: number;
  data: {
    id: number;
    name: Name[];
    type: 0 | 1;
    order: number;
  };
}) => {
  const [form] = useForm();
  const { id, name, order, type } = data;
  const { removeAttribute, updateAttribute, attributes } = useModel();
  const includeChildren = childrenCount(attributes, rowIndex) > 0;

  const submitHook = () => {
    const values = form.getFieldsValue();
    const { name, type, order } = values;
    const oldAttribute = attributes[rowIndex];
    const newAttribute = {
      ...oldAttribute,
      type,
      order,
      name: oldAttribute.name.map((item, i) => {
        switch (i) {
          case 0:
            return { ...item, value: name.zh };
          case 1:
            return { ...item, value: name.en };
          case 2:
            return { ...item, value: name.ru };
          default:
            return item;
        }
      }),
    };

    updateAttribute(rowIndex, newAttribute);
  };

  const { t, i18n } = useTranslation();
  const { visible, show, hide, onSubmit } = useDrawer(submitHook);

  const handleReset = () => form.resetFields();

  const handleRemove = () => {
    if (includeChildren) {
      return message.warning(t("model.editAttr.warning.includeChildren"));
    }

    removeAttribute(rowIndex);
  };

  const attributeName = `${id ? id + ":" : ""} ${
    name[nameLangMap(i18n.language)].value
  }`;

  return (
    <div style={{ margin: "4px 0" }}>
      <span className="mr-10 attribute-atom attribute-type-color">
        <span role="button" onClick={show}>
          {attributeName}
        </span>
        <CloseOutlined
          className="close-btn type-close-btn"
          role="button"
          style={{ marginLeft: 5, marginRight: -5 }}
          onClick={handleRemove}
        />
      </span>
      <Drawer
        title={t("model.editAttr.title")}
        visible={visible}
        onClose={hide}
        destroyOnClose
        width={359}
        extra={
          <Space>
            <Button onClick={handleReset}>{t("model.editAttr.reset")}</Button>
            <Button type="primary" onClick={onSubmit}>
              {t("model.editAttr.submit")}
            </Button>
          </Space>
        }
      >
        <AttributeTypeForm
          form={form}
          disabled={includeChildren}
          initialValues={{
            order,
            type,
            name: { zh: name[0].value, en: name[1].value, ru: name[2].value },
          }}
        />
      </Drawer>
    </div>
  );
};

export default EditAttribute;
