import { message, notification } from "antd";
import axios from "axios";

const DEFAULT_HOSTNAME = `http://localhost:${process.env.PORT || 3000}`;
const BASE_URL = new URL(process.env.REACT_APP_HOSTNAME || DEFAULT_HOSTNAME);
BASE_URL.pathname = "/api/v1";

const instance = axios.create({
  baseURL: BASE_URL.toString(),
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    const auth = window.localStorage.getItem("aikonLoggedUser");
    const token = auth ? JSON.parse(auth).token : undefined;
    config.headers.Authorization = `Bearer ${token}`;

    const lang = window.localStorage.getItem("i18nextLng");
    if (lang) config.headers["Accept-Language"] = lang;

    return config;
  },
  (error) => {
    notification["error"](error.toJSON());
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    const { error } = res.data;
    if (error) {
      console.log(error);
      notification["error"](error);
    }

    return res;
  },
  (error) => {
    notification["error"](error.toJSON());

    // 当出现 401 状态时，清除本地登录信息，并且跳转到对应的登录页面
    if (error.response.status === 401) {
      const auth = window.localStorage.getItem("aikonLoggedUser");
      const role = auth ? JSON.parse(auth).role : undefined;

      window.localStorage.removeItem("aikonLoggedUser");
      if (role === "ROLE_ADMIN") {
        window.location.replace("/admin");
      } else {
        window.location.reload();
      }
    }
    // 当出现 500 状态，并且返回值包括 have_son 字段时，提示用户是否删除子目录
    if (
      error.response.status === 500 &&
      error.response.data?.error?.message === "have_son"
    ) {
      message.error("该文件夹下有子文件夹，请先删除子文件夹");
    }

    return Promise.reject(error);
  }
);

export { BASE_URL };
export default instance;
