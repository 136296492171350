import { Button, Space } from "antd";
import React, { useEffect, useState } from "react";

interface OptionData {
  id: number;
  key: string;
  name: string;
}

interface CheckableButtonGroupProps {
  options: OptionData[];
  validList?: string[];
  value?: string;
  onChange?: (value: string) => void;
}

const CheckableButtonGroup = ({
  options,
  validList = [],
  value,
  onChange,
}: CheckableButtonGroupProps) => {
  const [checkedKey, setCheckedKey] = useState<string>(value || "");

  useEffect(() => {
    if (value !== checkedKey && value !== undefined) {
      setCheckedKey(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const triggerChange = (changedValue: string) => {
    onChange?.(changedValue);
  };

  const handleChange = (key: string) => {
    const newKey = checkedKey === key ? "" : key;

    if (value === undefined) setCheckedKey(newKey);
    triggerChange(newKey);
  };

  return (
    <Space>
      {options.map((btn) => (
        <Button
          key={btn.key}
          type={checkedKey === btn.key ? "primary" : "default"}
          onClick={() => handleChange(btn.key)}
          disabled={validList.length > 0 && !validList.includes(btn.key)}
        >
          {btn.name}
        </Button>
      ))}
    </Space>
  );
};

export default CheckableButtonGroup;
