import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import transZH from "./locales/zh.json";
import transEN from "./locales/en.json";
import transRU from "./locales/ru.json";
import zhCN from "antd/es/locale/zh_CN";
import enUS from "antd/es/locale/en_US";
import ruRU from "antd/es/locale/ru_RU";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "zh-CN",
    resources: {
      zh: {
        translation: transZH,
      },
      en: {
        translation: transEN,
      },
      ru: {
        translation: transRU,
      },
    },
  });

export const languages = [
  {
    key: "zh-CN",
    name: "中文",
  },
  {
    key: "en-US",
    name: "English",
  },
  {
    key: "ru-RU",
    name: "Русский",
  },
];

export const langMap = (lang: string) => {
  switch (lang) {
    case "zh-CN":
      return zhCN;
    case "ru-RU":
      return ruRU;
    default:
      return enUS;
  }
};

export const nameLangMap = (lang: string): number => {
  switch (lang) {
    case "zh-CN":
      return 0;
    case "en-US":
      return 1;
    case "ru-RU":
      return 2;
    default:
      return 0;
  }
};

export default i18n;
