import axios from "../libs/axios";

interface SpuListParams {
  q?: string;
  page?: number;
  limit?: number;
  cat?: string;
}

interface SpuSelectionParams {
  q?: number;
  page?: number;
  limit?: number;
  stock?: number;
  cat?: string;
  full?: number;
}

interface PointsData {
  spu_id: number;
  valid_start: number;
  valid_end: number;
  use_formula: boolean;
  q_coordinates: number[];
  h_coordinates: number[];
  eff_coordinates: number[];
  npsh_coordinates?: number[];
  size: number;
  freq: number;
  speed: number;
}

const getList = async (params?: SpuListParams) =>
  await axios.get("/spus", { params });

const getSpuName = async (id: string) => await axios.get(`/spus/${id}`);

const getInfo = async (id: string) => await axios.get(`/spus/${id}/info`);

const getPoints = async (id: string) => await axios.get(`/spus/${id}/points`);

const postPoints = async (id: string, points: PointsData) =>
  await axios.post(`/spus/${id}/points`, points);

const putPoints = async (id: string, points: PointsData) =>
  await axios.put(`/spus/${id}/points`, points);

const deletePoints = async (id: string) =>
  await axios.delete(`/spus/${id}/points`);

const getPdfParameter = async (id: string) =>
  await axios.get(`/spus/${id}/pdfParameter`);

const postPdfExport = async (id: string, params: any) =>
  await axios.post(`/spus/${id}/exportPdf`, params, { responseType: "blob" });

const getCategories = async () => await axios.get("/categories");

const getSelection = async (params?: SpuSelectionParams) =>
  await axios.get("/selection", { params });

const getSizeAndAttrByLabel = async (
  id: string,
  sizeLabel?: string,
  attrLabel?: string
) =>
  await axios.get(`/spus/${id}/label?size=${sizeLabel}&attribute=${attrLabel}`);

const spuServices = {
  getSpuName,
  getList,
  getInfo,
  getPoints,
  postPoints,
  putPoints,
  deletePoints,
  getPdfParameter,
  postPdfExport,
  getCategories,
  getSelection,
  getSizeAndAttrByLabel,
};

export default spuServices;
