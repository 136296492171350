import { useState } from "react";

const usePagination = (
  initCurrent: number = 1,
  initPageSize: number = 20,
  initTotal: number = 0
) => {
  const [current, setCurrent] = useState(initCurrent);
  const [pageSize, setPageSize] = useState(initPageSize);
  const [total, setTotal] = useState(initTotal);

  const setPagination = (
    newCurrent: number,
    newPageSize: number,
    newTotal: number
  ) => {
    setCurrent(newCurrent);
    setPageSize(newPageSize);
    setTotal(newTotal);
  };

  const onCurrentChange = (
    newCurrent: number,
    newPageSize: number | undefined
  ) => setPagination(newCurrent, newPageSize ? newPageSize : pageSize, total);

  return { current, pageSize, total, setPagination, onCurrentChange };
};

export default usePagination;
