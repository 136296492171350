import { Card, Empty, Button, Modal, Form } from "antd";
import { useTranslation } from "react-i18next";
import SpuForm from "../../SpuChart/components/SpuForm";
import useAuth from "../../../hooks/useAuth";
import useLoading from "../../../hooks/useLoading";
import useSpuPoints from "../../../stores/spuPoints";
import { useParams } from "react-router-dom";
import useModal from "../../../hooks/useModal";

const { useForm } = Form;

const EmptyChart = () => {
  const [form] = useForm();
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const { create } = useSpuPoints();

  const { show, hide, visible } = useModal();

  const handleCreateOk = async () => {
    const values = await form.validateFields();
    await create(String(id), values);

    hide();
  };

  const [onSubmit, loading] = useLoading(handleCreateOk);

  return (
    <Card>
      <div
        style={{
          minHeight: "70vh",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Empty description={t("chart.empty")}>
          {isAdmin && (
            <>
              <Button type="primary" onClick={show}>
                {t("chart.create")}
              </Button>
              <Modal
                title={t("spuForm.create.title")}
                visible={visible}
                onCancel={hide}
                onOk={onSubmit}
                okText={t("confirm.ok")}
                cancelText={t("confirm.cancel")}
                confirmLoading={loading}
              >
                <SpuForm name="create" layout="vertical" form={form} />
              </Modal>
            </>
          )}
        </Empty>
      </div>
    </Card>
  );
};

export default EmptyChart;
