import { AxiosResponse } from "axios";
import create from "zustand";
import modelServices, { AttrData, ModelData } from "../services/model";

export interface Name {
  id?: string;
  value: string;
  lang: string;
}

interface AttributeBaseState {
  id?: number;
  order: number;
  name: Name[];
}

export interface SubAttributeState extends AttributeBaseState {
  attrId?: number;
  src: number;
  path: string;
}

export interface AttributeState extends AttributeBaseState {
  modelId?: number;
  type: 0 | 1; // 0: text, 1: image
  list: SubAttributeState[];
}

interface ModelInitState {
  id: number;
  name: string;
  loading: boolean;
  attributes: AttributeState[];
}

interface ModelState extends ModelInitState {
  setName: (name: string) => void;
  resetAll: () => void;
  appendAttribute: (attr: AttributeState) => void;
  removeAttribute: (index: number) => void;
  updateAttribute: (index: number, attr: AttributeState) => void;
  appendSubAttribute: (index: number, attr: SubAttributeState) => void;
  removeSubAttribute: (rowIndex: number, colIndex: number) => void;
  updateSubAttribute: (
    rowIndex: number,
    colIndex: number,
    attr: SubAttributeState
  ) => void;
  fetchModel: (id: number) => void;
  updateModel: (id: number, model: ModelData) => void;
  createModel: () => Promise<AxiosResponse<any>>;
}

const parseAttrBase = (attr: any): AttributeBaseState => {
  return {
    id: attr.id,
    order: attr.order_index,
    name: attr.name_lang,
  };
};

const parseSubAttr = (attr: any): SubAttributeState => {
  return {
    ...parseAttrBase(attr),
    attrId: attr.attr_id,
    src: attr.src,
    path: attr.path,
  };
};

const parseAttr = (attrs: any[]): AttributeState[] => {
  return attrs.map((attr) => ({
    ...parseAttrBase(attr),
    modelId: attr.spu_model,
    type: attr.type,
    list: attr.list.map((subAttr: any) => parseSubAttr(subAttr)),
  }));
};

const subAttrToRequestData = (attr: SubAttributeState) => {
  let newAttr: any = {
    path: attr.path,
    src: attr.src,
    order_index: attr.order,
    name_lang: attr.name,
  };
  if (attr.attrId) {
    newAttr = {
      ...newAttr,
      attr_id: attr.attrId,
    };
  }

  return newAttr;
};

const attributesToRequestData = (attributes: AttributeState[]): AttrData[] => {
  return attributes.map((attr) => {
    let newAttr: any = {
      order_index: attr.order,
      name_lang: attr.name,
      type: attr.type,
      list: attr.list.map((item) => subAttrToRequestData(item)),
    };
    if (attr.id) {
      newAttr = {
        ...newAttr,
        id: attr.id,
        spu_model: attr.modelId,
      };
    }

    return newAttr;
  });
};

const initialState: ModelInitState = {
  id: 0,
  name: "",
  loading: false,
  attributes: [],
};

const useModel = create<ModelState>((set, get) => ({
  ...initialState,
  setName: (name: string) => set({ name }),
  resetAll: () => set({ ...initialState }),
  appendAttribute: (newAttribute: AttributeState) => {
    const newAttributeName = newAttribute.name[0].value;
    const attributeNameList = get().attributes.map(
      (item) => item.name[0].value
    );

    if (attributeNameList.includes(newAttributeName)) return;
    return set(({ attributes }) => ({
      attributes: [...attributes, newAttribute],
    }));
  },
  removeAttribute: (index: number) =>
    set(({ attributes }) => ({
      attributes: attributes.filter((_, i) => i !== index),
    })),
  updateAttribute(index: number, newAttribute: AttributeState) {
    const newAttributes = get().attributes.map((attr, i) => {
      if (i === index) {
        return newAttribute;
      }
      return attr;
    });

    set({ attributes: newAttributes });
  },
  appendSubAttribute: (index: number, attr: SubAttributeState) => {
    const newAttributes = get().attributes.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          list: [...item.list, attr],
        };
      }
      return item;
    });

    set({ attributes: newAttributes });
  },
  removeSubAttribute: (rowIndex: number, colIndex: number) =>
    set(({ attributes }) => ({
      attributes: attributes.map((item, i) => {
        if (i === rowIndex) {
          return {
            ...item,
            list: item.list.filter((_, j) => j !== colIndex),
          };
        }
        return item;
      }),
    })),
  updateSubAttribute: (
    rowIndex: number,
    colIndex: number,
    attr: SubAttributeState
  ) => {
    const newAttributes = get().attributes.map((item, i) => {
      if (i === rowIndex) {
        return {
          ...item,
          list: item.list.map((subItem, j) => {
            if (j === colIndex) {
              return attr;
            }
            return subItem;
          }),
        };
      }
      return item;
    });

    set({ attributes: newAttributes });
  },
  fetchModel: async (id: number) => {
    set({ loading: true });
    const res = await modelServices.getModel(String(id));
    const { model, attrArr } = res.data.response;
    const newName = model.spu_model_name;
    const newAttributes = parseAttr(attrArr);
    set({ id, name: newName, attributes: newAttributes, loading: false });
  },
  updateModel: async () => {
    await modelServices.putModel(get().id, {
      name: get().name,
      attrArr: attributesToRequestData(get().attributes),
    });
  },
  createModel: async () =>
    await modelServices.postModel({
      name: get().name,
      attrArr: attributesToRequestData(get().attributes),
    }),
}));

export default useModel;
