import create from "zustand";
import spuServices from "../services/spu";

interface SpuPdfParam {
  modules: {
    key: string;
    name: string;
    checked: boolean;
  }[];
  paperSize: "A0" | "A1" | "A2" | "A3" | "A4" | "A3" | "A5";
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  company: {
    name: string;
    founder: string;
    phone: string;
    email: string;
    date: string;
  };
  filename: string;
  images: {
    performance: string;
    multiSpeed: string;
    seriesParallel: string;
  };
  loading: boolean;
}

interface SpuPdfParamState extends SpuPdfParam {
  fetch: (id: string) => void;
  reset: () => void;
  setPerformanceImage: (image: string) => void;
  setMultiSpeedImage: (image: string) => void;
  setSeriesParallel: (image: string) => void;
}

const parsePdfParameter = (data: any) => {
  const { lang, page_set, company_head } = data;
  const { model_view, spu } = lang;

  return {
    modules: model_view.map((item: any) => ({
      key: item.key,
      name: item.desc,
      checked: true,
    })),
    paperSize: page_set.format,
    margin: {
      top: page_set.margin_top,
      right: page_set.margin_right,
      bottom: page_set.margin_bottom,
      left: page_set.margin_left,
    },
    company: {
      name: company_head.name,
      founder: company_head.founder,
      email: company_head.email,
      phone: company_head.mobile,
      date: company_head.date,
    },
    filename: spu,
  };
};

const initialState: SpuPdfParam = {
  modules: [],
  paperSize: "A0",
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  company: {
    name: "",
    founder: "",
    phone: "",
    email: "",
    date: "",
  },
  filename: "",
  images: {
    performance: "",
    multiSpeed: "",
    seriesParallel: "",
  },
  loading: false,
};

const useSpuPdfParam = create<SpuPdfParamState>((set, get) => ({
  ...initialState,
  fetch: async (id: string) => {
    try {
      set({ loading: true });
      const res = await spuServices.getPdfParameter(id);
      const data = res.data.response;
      const newPdfParameter = parsePdfParameter(data);

      set({ ...initialState, ...newPdfParameter });
    } catch (e) {
      console.error(e);
      set({ ...initialState });
    } finally {
      set({ loading: false });
    }
  },
  reset: () => set({ ...initialState }),
  setPerformanceImage: (image: string) =>
    set(({ images }) => ({ images: { ...images, performance: image } })),
  setMultiSpeedImage: (image: string) =>
    set(({ images }) => ({ images: { ...images, multiSpeed: image } })),
  setSeriesParallel: (image: string) =>
    set(({ images }) => ({ images: { ...images, seriesParallel: image } })),
}));

export default useSpuPdfParam;
