import { useState } from "react";
import { Button, Input, Space } from "antd";
import { EditOutlined, EnterOutlined } from "@ant-design/icons";

interface EditableTextProps {
  value?: string;
  onFinish: (changedValue: string) => void;
}

const EditableText = ({ value, onFinish }: EditableTextProps) => {
  const [editing, setEditing] = useState(false);
  const writeMode = () => setEditing(true);
  const readMode = () => setEditing(false);

  const [text, setText] = useState(value || "");

  const handleSubmit = () => {
    onFinish(text);
    readMode();
  };

  if (editing) {
    return (
      <Space>
        <Input value={text} onChange={(e) => setText(e.target.value)} />
        <Button type="text" icon={<EnterOutlined />} onClick={handleSubmit} />
      </Space>
    );
  }

  return (
    <>
      {text}
      <Button type="text" icon={<EditOutlined />} onClick={writeMode} />
    </>
  );
};

export default EditableText;
