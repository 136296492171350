import ChartLayout from "../../../components/ChartLayout";
import PerformanceForm from "./PerformanceForm";
import PerformanceChart from "./PerformanceChart";
import PerformanceResult from "./PerformanceResult";
import useSpuPoints from "../../../stores/spuPoints";
import EmptyChart from "../components/EmptyChart";
import Loading from "../components/Loading";

const Performance = () => {
  const { name, info, dataset, performance, loading } = useSpuPoints();
  const { fieldsValue, fieldsResult } = performance;

  if (loading) return <Loading />;

  if (!name || !dataset) return <EmptyChart />;

  return (
    <ChartLayout
      chart={
        <PerformanceChart
          key={name}
          name={name}
          info={info}
          dataset={dataset}
          performance={fieldsValue}
        />
      }
      form={<PerformanceForm />}
      result={<PerformanceResult fieldsResult={fieldsResult} />}
    />
  );
};

export default Performance;
