import React from "react";

const FixedFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        position: "fixed",
        right: 0,
        bottom: 0,
        minHeight: "44px",
        width: "100%",
        zIndex: 99,
        backgroundColor: "#fff",
        padding: "10px 4% 0px 4%",
        boxShadow:
          "0 -6px 16px -8px rgb(0 0 0 / 8%), 0 -9px 28px 0 rgb(0 0 0 / 5%), 0 -12px 48px 16px rgb(0 0 0 / 3%)",
      }}
    >
      {children}
    </div>
  );
};

export default FixedFooter;
