import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Radio } from "antd";
import { useTranslation } from "react-i18next";
import useSpuPoints from "../../../stores/spuPoints";

const { useForm } = Form;

const Unit = ({ name, style }: { name: string; style?: any }) => {
  return <span style={{ display: "block", width: 30, ...style }}>{name}</span>;
};

const PerformanceSpeedForm = () => {
  const [form] = useForm();
  const { t } = useTranslation();
  const {
    info,
    setPerformanceSpeedValue,
    setPerformanceSpeedItems,
    resetPerformanceSpeed,
  } = useSpuPoints();
  const { type } = info;

  const handleSubmit = (values: any) => {
    const { type, quantity, head, sg, speedItems } = values;

    if (quantity !== undefined && head !== undefined) {
      setPerformanceSpeedValue({
        type,
        quantity,
        head,
        sg: sg || 1,
      });
    }

    if (speedItems && speedItems.length > 0) {
      setPerformanceSpeedItems(speedItems);
    }
  };

  const handleReset = () => {
    form.resetFields();
    resetPerformanceSpeed();
  };

  return (
    <Form
      form={form}
      name="settings"
      layout="vertical"
      onReset={handleReset}
      onFinish={handleSubmit}
      initialValues={{ type: 0, sg: 1 }}
    >
      <Form.Item label={t("chart.formula")} name="type">
        <Radio.Group>
          <Radio.Button value={0}>Q² ÷ H</Radio.Button>
          <Radio.Button value={1} disabled={!type}>
            Q³ ÷ H²
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Q" name="quantity" required>
        <InputNumber
          style={{ width: "100%" }}
          precision={1}
          addonAfter={<Unit name="m³/h" />}
        />
      </Form.Item>
      <Form.Item label="H" name="head" required>
        <InputNumber
          style={{ width: "100%" }}
          precision={1}
          addonAfter={<Unit name="m" />}
        />
      </Form.Item>
      <Form.Item label={t("chart.sg")} name="sg">
        <InputNumber
          style={{ width: "100%" }}
          precision={2}
          min={0.2}
          max={5}
        />
      </Form.Item>
      <Form.Item
        label={t("spuForm.rpm.label")}
        rules={[
          {
            validator: async (_: any, names: any) => {
              if (!names || names.length < 2) {
                return Promise.reject(new Error(t("spuForm.rpm.error")));
              }
            },
          },
        ]}
      >
        <Form.List name="speedItems">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.key}>
                  <Form.Item
                    {...field}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: t("speedChart.speed.error"),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={t("speedChart.speed.placeholder")}
                      style={{ width: "90%" }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    style={{ width: "10%" }}
                    onClick={() => remove(field.name)}
                  />
                </Form.Item>
              ))}
              <Form.Item noStyle>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  style={{ width: "100%" }}
                >
                  {t("speedChart.speed.add")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item noStyle>
        <Button type="primary" htmlType="submit">
          {t("chart.submit")}
        </Button>
        <Button type="ghost" htmlType="reset" style={{ marginLeft: 10 }}>
          {t("chart.clear")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PerformanceSpeedForm;
