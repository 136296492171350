import { Image } from "antd";
import React from "react";
import { SpuImageAttributeData } from "../../../types";

interface ImageAttributeProps {
  attribute: SpuImageAttributeData;
  style: React.CSSProperties;
}

const ImageAttribute = ({ attribute, style }: ImageAttributeProps) => {
  const src = `${attribute.image}&size=big`;

  return (
    <div style={style}>
      <Image src={src} />
    </div>
  );
};

export default ImageAttribute;
