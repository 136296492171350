import React from "react";
import { Card, Form, Input, Button, Checkbox, Dropdown, Menu } from "antd";
import { UserOutlined, LockOutlined, DownOutlined } from "@ant-design/icons";
import Brand from "./Brand";
import "./LoginDialog.css";
import { useTranslation } from "react-i18next";
import { languages } from "../i18n";

interface LoginDialogProps {
  onLogin: (values: object) => void;
  loading: boolean;
}

const LoginDialog = ({ onLogin, loading }: LoginDialogProps) => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (key: string) => {
    // 语言菜单选择
    const langs = languages.map((lang) => lang.key);
    if (langs.includes(key)) {
      i18n.changeLanguage(key);
    }
  };

  const menu = (
    <Menu
      onClick={({ key }) => handleLanguageChange(key)}
      selectedKeys={[i18n.language]}
    >
      {languages.map((lang) => (
        <Menu.Item key={lang.key}>{lang.name}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Card id="login-dialog">
      <div className="login-dialog-language">
        <Dropdown overlay={menu}>
          <Button type="link">
            {t("chart.language")} <DownOutlined />
          </Button>
        </Dropdown>
      </div>
      <Brand logoSize="44px" fontSize="32px" lineHeight="32px" />
      <Form id="login-dialog-form" size="large" onFinish={onLogin}>
        <Form.Item
          name="account"
          rules={[{ required: true, message: t("login.account.error") }]}
        >
          <Input
            prefix={<UserOutlined className="login-input-icon" />}
            placeholder={t("login.account.placeholder")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: t("login.password.error") }]}
        >
          <Input
            prefix={<LockOutlined className="login-input-icon" />}
            type="password"
            placeholder={t("login.password.placeholder")}
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>{t("login.remember")}</Checkbox>
          </Form.Item>
          <Button type="link" size="small" className="login-forget-password">
            {t("login.forgot")}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
          >
            {t("login.submit")}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default LoginDialog;
