import { Button, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import useStorage from "../../../stores/storage";
import { useTranslation } from "react-i18next";

const Delete = () => {
  const { t } = useTranslation();
  const { deleteObjects, fetchCurrentDirectory, fetchFolderTree, selected } =
    useStorage();

  const handleDelete = async () => {
    await deleteObjects(selected);
    await fetchCurrentDirectory();
    await fetchFolderTree();
  };
  return (
    <Tooltip title={t("fileManager.tool.delete.title")}>
      <Button type="text" icon={<DeleteOutlined />} onClick={handleDelete} />
    </Tooltip>
  );
};

export default Delete;
