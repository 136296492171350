import { Button, Form, InputNumber, Radio } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface SeriesParallelFormProps {
  style?: object;
  onFinish?: (t: any) => void;
  onReset?: (t: any) => void;
  form?: any;
}

const SeriesParallelForm = ({
  style,
  onFinish,
  onReset,
  form,
}: SeriesParallelFormProps) => {
  const { t } = useTranslation();

  return (
    <Form
      name="seriesParallelForm"
      layout="vertical"
      style={style}
      onFinish={onFinish}
      onReset={onReset}
      form={form}
      initialValues={{ type: "series", quantity: 1 }}
    >
      <Form.Item
        label={t("seriesParallelChart.type.label")}
        name="type"
        rules={[
          {
            required: true,
            message: t("seriesParallelChart.type.error"),
          },
        ]}
      >
        <Radio.Group>
          <Radio.Button value="series">
            {t("seriesParallelChart.type.series")}
          </Radio.Button>
          <Radio.Button value="parallel">
            {t("seriesParallelChart.type.parallel")}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={t("seriesParallelChart.quantity.label")}
        name="quantity"
        rules={[
          {
            required: true,
            message: t("seriesParallelChart.quantity.error"),
          },
        ]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t("seriesParallelChart.submit")}
        </Button>
        <Button style={{ marginLeft: "10px" }} onClick={onReset}>
          {t("seriesParallelChart.clear")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SeriesParallelForm;
