import { FileImageOutlined, FileTextOutlined } from "@ant-design/icons";
import { Form, InputNumber, Radio } from "antd";
import { useTranslation } from "react-i18next";
import InputName from "../../components/InputName";

interface Props {
  form: any;
  disabled?: boolean;
  initialValues?: {
    name: {
      zh: string;
      en: string;
      ru: string;
    };
    order: number;
    type: 0 | 1;
  };
}

const AttributeTypeForm = ({
  form,
  disabled = false,
  initialValues,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={
        initialValues || {
          name: {
            zh: "",
            en: "",
            ru: "",
          },
          order: 100,
          type: 0,
        }
      }
    >
      <Form.Item label={t("model.attrForm.name")} name="name" required>
        <InputName />
      </Form.Item>
      <Form.Item label={t("model.attrForm.sort")} name="order" required>
        <InputNumber min={0} precision={0} />
      </Form.Item>
      <Form.Item label={t("model.attrForm.type")} name="type" required>
        <Radio.Group optionType="button" disabled={disabled}>
          <Radio.Button value={0}>
            <FileTextOutlined style={{ marginRight: 4 }} />
            <span>{t("model.attrForm.text")}</span>
          </Radio.Button>
          <Radio.Button value={1}>
            <FileImageOutlined style={{ marginRight: 4 }} />
            <span>{t("model.attrForm.image")}</span>
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};

export default AttributeTypeForm;
