import { useEffect } from "react";
import { Card, Col, PageHeader, Row } from "antd";
import FileList from "./FileList";
import FileGrid from "./FileGrid";
import FileToolbox from "./FileToolbox";
import useStorage from "../../stores/storage";
import FileTree from "./FileTree";
import BaseLayout from "../../components/BaseLayout";
import FileUpload from "./FileToolbox/FileUpload";
import NewFolder from "./FileToolbox/NewFolder";
import { useTranslation } from "react-i18next";

const FileDisplayArea = () => {
  const { view } = useStorage();

  if (view === "grid") return <FileGrid />;
  return <FileList />;
};

const FileManagerBody = () => {
  const { t } = useTranslation();
  const {
    fetchCurrentDirectory,
    fetchFolderTree,
    updateHistory,
    currentRef,
    pathList,
  } = useStorage();

  const refresh = async () => {
    if (currentRef) {
      await fetchCurrentDirectory();
    }
    if (pathList.length === 0) {
      await fetchFolderTree();
    } else {
      updateHistory(String(currentRef), pathList[0]);
    }
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseLayout title={t("route.fileManager")}>
      <PageHeader
        title={t("route.fileManager")}
        style={{ padding: "16px 0" }}
        extra={[<NewFolder key="new" />, <FileUpload key="upload" />]}
      />
      <Card style={{ width: "100%" }} bodyStyle={{ padding: "16px 0px" }}>
        <FileToolbox />
        <Row wrap={false}>
          <Col flex="256px" style={{ margin: "0 8px 0 16px" }}>
            <FileTree />
          </Col>
          <Col flex="auto" style={{ margin: "0px 16px 0px 8px" }}>
            <FileDisplayArea />
          </Col>
        </Row>
      </Card>
    </BaseLayout>
  );
};

const FileManager = () => {
  const { currentRef, resetAll } = useStorage();

  useEffect(() => {
    resetAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FileManagerBody key={currentRef} />;
};

export default FileManager;
