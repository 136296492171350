import React, { useState } from "react";
import { EChartsOption } from "../../../components/Chart";
import { DataSource } from "../../../types";
import { Form } from "antd";
import Chart from "../../../components/Chart";
import SpeedForm from "./SpeedForm";
import { renderLabel } from "../../../libs/echar";
import ChartLayout from "../../../components/ChartLayout";
import useChartImage from "../../../hooks/useChartImage";
import { EChartsType } from "echarts/core";

const renderSpeedLabel = (speed: number) => renderLabel(`${speed} RPM`);

const SpeedChart = ({ dataSource }: { dataSource: DataSource }) => {
  const initSeries: any = [
    {
      id: 0,
      name: "original",
      type: "line",
      label: renderSpeedLabel(dataSource.speed),
      smooth: true,
      symbolSize: 0,
      data: dataSource.QH.dummyPoints,
      animation: false,
    },
  ];

  const initOption: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      feature: {
        saveAsImage: { name: dataSource.name + "_rpm" },
      },
    },
    xAxis: { name: "m³/h", type: "value", min: 0 },
    yAxis: { name: "H(m)", type: "value", min: 0 },
    series: initSeries,
  };

  const [option, setOption] = useState(initOption);
  const [form] = Form.useForm();

  const addSpeedItems = (speedItems: number[]) => {
    const speedSeries = speedItems.map((speed, index) => {
      const k = speed / dataSource.speed;
      const data = dataSource.QH.dummyPoints.map((p) => [p[0] * k, p[1] * k]);

      return {
        id: index + 1,
        name: `Speed ${index + 1}`,
        type: "line",
        label: renderSpeedLabel(speed),
        symbolSize: 0,
        data,
        animation: false,
      };
    });

    setOption({
      ...initOption,
      series: [...initSeries, ...speedSeries],
    });
  };

  const { addImage, delImage, exportTemplate } = useChartImage();

  const resetChart = () => {
    setOption(initOption);
    form.resetFields();
    delImage("speedChart");
  };

  const handleChartChange = (instance: EChartsType) => {
    const result = instance.getDataURL(exportTemplate as any);
    const { speedItems } = form.getFieldsValue();
    instance.getDataURL();
    if (speedItems.length > 0) addImage("speedChart", result);
  };

  return (
    <ChartLayout
      chart={
        <Chart
          option={option}
          notMerge
          style={{ width: "500px", height: "600px", margin: "auto" }}
          onChange={handleChartChange}
        />
      }
      form={
        <SpeedForm
          onFinish={(values: any) => addSpeedItems(values.speedItems)}
          onReset={resetChart}
          form={form}
        />
      }
    />
  );
};

export default SpeedChart;
