/* eslint-disable react-hooks/exhaustive-deps */
import {
  SpuPointsDataset,
  SeriesParallelForm,
  PerformanceFormField,
} from "../../../stores/spuPoints";
import Chart, { EChartsOption } from "../../../components/Chart";
import { useEffect, useState } from "react";
import { rangeRight, parseSpuPointsDataset } from "../../../utils";
import {
  exportTemplate,
  genRenderItem,
  renderTypeLabel,
} from "../../../libs/echar";
import { useTranslation } from "react-i18next";
import useSpuPdfParam from "../../../stores/spuPdfParam";
import { EChartsType } from "echarts/core";

interface SeriesParallelChartProps {
  name: string;
  dataset: SpuPointsDataset;
  performance: PerformanceFormField;
  seriesParallel: SeriesParallelForm;
}

const SeriesParallelChart = ({
  name,
  dataset,
  performance,
  seriesParallel,
}: SeriesParallelChartProps) => {
  const { t } = useTranslation();

  const initialOption: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      feature: {
        saveAsImage: { name: name + "_sp" },
      },
    },
    xAxis: { name: "m³/h", type: "value", min: 0 },
    yAxis: { name: "H(m)", type: "value", min: 0 },
  };

  const [option, setOption] = useState(initialOption);

  useEffect(() => {
    const { type, quantity } = seriesParallel;
    const { QH } = parseSpuPointsDataset(dataset);

    const newSeries: any = rangeRight(quantity, 1).map((n) => {
      const k = n;
      const data = QH.dummyPoints.map((p) => {
        if (type === "series") return [p[0], p[1] * k];
        else return [p[0] * k, p[1]];
      });

      return {
        name: `Line ${n}`,
        type: "line",
        symbolSize: 0,
        data,
        animation: false,
      };
    });

    const { quantity: q, head: h } = performance;
    let series = [
      ...newSeries,
      {
        name: "text 1",
        type: "custom",
        renderItem: renderTypeLabel(
          400,
          50,
          type === "series"
            ? t("seriesParallelChart.type.series")
            : t("seriesParallelChart.type.parallel")
        ),
        data: [{ x: 0, y: 0 }],
      },
    ];

    if (q !== undefined && h !== undefined && quantity > 1) {
      series = [
        ...series,
        {
          name: "intersection point",
          type: "custom",
          renderItem: genRenderItem("circle", q, h),
          data: [
            {
              name: "intersection point",
              xAxis: q,
              yAxis: h,
            },
          ],
          z: 100,
        },
      ];
    }

    setOption({
      ...initialOption,
      series,
    });
  }, [
    JSON.stringify(seriesParallel),
    JSON.stringify(performance),
    JSON.stringify(dataset),
  ]);

  const { setSeriesParallel } = useSpuPdfParam();

  const handleChartChange = (instance: EChartsType) => {
    const result = instance.getDataURL(exportTemplate as any);
    const { quantity } = seriesParallel;
    instance.getDataURL();
    if (quantity > 1) setSeriesParallel(result);
  };

  return (
    <Chart
      option={option}
      notMerge
      style={{ width: 500, height: 600, margin: "auto" }}
      onChange={handleChartChange}
    />
  );
};

export default SeriesParallelChart;
