import { useState } from "react";
import {
  Row,
  Col,
  Image,
  Descriptions,
  Card,
  Divider,
  Form,
  Select,
  Button,
  Space,
} from "antd";
import SpecForm from "../SpecForm";
import { PdfParameterValues, SpuInfoData } from "../../../types";
import "./SpuInfo.css";
import { useTranslation } from "react-i18next";

const { useForm } = Form;
const { Option } = Select;

interface SpuInfoProps {
  dataSource: SpuInfoData;
  onLabelChange: (
    id: string,
    sizeLabel: string,
    attributeLabel: string
  ) => void;
  onSpecChange: (sku: string, specData: any) => void;
  pdfParameter: PdfParameterValues;
}

// 分类介绍
// - 分类图片
// - 分类描述
// 产品属性
// 规格
// 产品详情
// - 曲线图
// - 爆炸图
// - ...
const SpuInfo = ({
  dataSource: info,
  onLabelChange,
  onSpecChange,
  pdfParameter,
}: SpuInfoProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const initialValues = { sizeLabel: "default", attributeLabel: "default" };
  const [currLabel, setCurrLabel] = useState({ ...initialValues });
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = async (values: any) => {
    try {
      const { sizeLabel, attributeLabel } = values;

      setLoading(true);
      await onLabelChange(String(info.id), sizeLabel, attributeLabel);
      setCurrLabel({
        sizeLabel,
        attributeLabel,
      });
      setLoading(false);
    } catch (error) {}
  };

  const handleReset = () => {
    form.resetFields();
    setCurrLabel({ ...initialValues });
  };

  return (
    <>
      <Card title={t("spuInfo.label.title")} style={{ marginBottom: 24 }}>
        <Form
          form={form}
          layout="horizontal"
          onFinish={handleChange}
          initialValues={{ ...initialValues }}
        >
          <Row justify="space-between">
            <Col>
              <Space>
                <Form.Item
                  label={t("spuInfo.label.size")}
                  name="sizeLabel"
                  style={{ marginBottom: 0 }}
                >
                  <Select style={{ minWidth: 150 }}>
                    {info.label.size.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t("spuInfo.label.attribute")}
                  name="attributeLabel"
                  style={{ marginBottom: 0 }}
                >
                  <Select style={{ minWidth: 150 }}>
                    {info.label.attribute.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Space>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Space>
                  <Button type="default" onClick={handleReset}>
                    {t("spuInfo.label.reset")}
                  </Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {t("spuInfo.label.submit")}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card title={t("spuInfo.categoryDesc")} style={{ marginBottom: "24px" }}>
        <Row justify="center" gutter={16}>
          <Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={6}>
            <div style={{ width: "300px", margin: "0 auto" }}>
              <Image src={`${info.category.image}&size=big`} />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={14} xl={16} xxl={18}>
            <div
              dangerouslySetInnerHTML={{ __html: info.category.content }}
              style={{ maxWidth: "1000px" }}
            ></div>
          </Col>
        </Row>
      </Card>
      <Card title={t("spuInfo.productProp")} style={{ marginBottom: "24px" }}>
        {info.attributes.length > 0 && (
          <Descriptions
            title={currLabel.attributeLabel !== "default" && "default"}
            bordered
            size="middle"
          >
            {info.attributes.map((attr) => (
              <Descriptions.Item key={attr.name} label={attr.name}>
                {attr.item}
              </Descriptions.Item>
            ))}
          </Descriptions>
        )}
        {currLabel.attributeLabel !== "default" && info.otherAttributes && (
          <Descriptions
            bordered
            size="middle"
            style={{ marginTop: 24 }}
            title={currLabel.attributeLabel}
          >
            {info.otherAttributes.map((attr) => (
              <Descriptions.Item key={attr.name} label={attr.name}>
                {attr.item}
              </Descriptions.Item>
            ))}
          </Descriptions>
        )}
        {info.attributes.length > 0 && <Divider />}
        <SpecForm
          specifications={info.specifications}
          sku={info.sku}
          pdfParameter={pdfParameter}
          id={info.id.toString()}
          spu={info.spu}
          onSpecChange={onSpecChange}
        />
      </Card>
      <Card title={t("spuInfo.productDetail")} style={{ marginBottom: "24px" }}>
        {info.details.image && (
          <>
            <h4>{t("spuInfo.curve")}</h4>
            <div
              style={{
                maxWidth: "500px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Image src={`${info.details.image}&size=big`} />
            </div>
          </>
        )}
      </Card>
      <Card title={t("spuInfo.introduction")}>
        {/* 文本属性 */}
        {info.textAttributes.map((attr) => (
          <div key={attr.name} className="mb-20">
            <h4>{attr.name}</h4>
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: attr.item,
              }}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                overflow: "auto",
              }}
            ></div>
          </div>
        ))}
        {/* 第一个图片属性 */}
        {info.imageAttributes.length > 0 && (
          <div className="mb-20">
            <h4>{info.imageAttributes[0].name}</h4>
            <div
              style={{
                maxWidth: "400px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Image src={`${info.imageAttributes[0].image}&size=big`} />
            </div>
          </div>
        )}
        {/* 描述 */}
        <div className="mb-20">
          <h4>{t("spuInfo.content")}</h4>
          {currLabel.sizeLabel !== "default" && info.content && (
            <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 7 }}>
              default
            </div>
          )}
          {info.content && (
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: info.content }}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                overflow: "auto",
              }}
            ></div>
          )}
          {currLabel.sizeLabel !== "default" && info.otherContent && (
            <>
              <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 7 }}>
                {currLabel.sizeLabel}
              </div>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: info.otherContent }}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  overflow: "auto",
                }}
              ></div>
            </>
          )}
        </div>
        {/* 剩下的图片属性 */}
        {info.imageAttributes.length > 1 &&
          info.imageAttributes.slice(1).map((attr, index) => (
            <div key={attr.name} className="mb-20">
              <h4>{attr.name}</h4>
              {currLabel.attributeLabel !== "default" && index === 0 && (
                <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 7 }}>
                  default
                </div>
              )}
              <div
                style={{
                  maxWidth: "400px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Image src={`${attr.image}&size=big`} />
              </div>
              {currLabel.attributeLabel !== "default" &&
                index === 0 &&
                info.otherImageAttributes && (
                  <>
                    <div
                      style={{ fontSize: 14, fontWeight: 700, marginBottom: 7 }}
                    >
                      {currLabel.attributeLabel}
                    </div>
                    <div
                      style={{
                        maxWidth: "400px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <Image
                        src={`${info.otherImageAttributes[0].image}&size=big`}
                      />
                    </div>
                  </>
                )}
            </div>
          ))}
      </Card>
    </>
  );
};

export default SpuInfo;
