import { Card, Spin } from "antd";

const Loading = () => {
  return (
    <Card>
      <div
        style={{
          minHeight: "70vh",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Spin />
      </div>
    </Card>
  );
};

export default Loading;
