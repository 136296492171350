import { useState } from "react";
import { Button, Row, Col, Form, Space } from "antd";
import { SkuData, SpecificationData } from "../../../types";
import FixedFooter from "../../../components/FixedFooter";
import CheckableButtonGroup from "./CheckableButtonGroup";
import { mapand } from "../../../utils";
import { useTranslation } from "react-i18next";
import useSpuInfo from "../../../stores/spuInfo";
import PrintPdf from "../Tools/PrintPdf";

interface SpecFormProps {
  specifications: SpecificationData[];
  sku: SkuData[];
}

const keyNameListToValues = (sku: SkuData | undefined) => {
  if (!sku) return {};

  const specKeyList = sku.keyName.split(" ");
  const reducer = (acc: any, curr: any) => {
    const [key, value] = curr.split("/");

    return {
      ...acc,
      [key]: value,
    };
  };

  return specKeyList.reduce(reducer, {});
};

export const keyNameListToSpecData = (
  sku: SkuData | undefined,
  spec: SpecificationData[]
) => {
  if (!sku || !spec) return [];

  const specKeyList = sku.keyName.split(" ").map((item) => item.split("/")[1]);

  return spec.map((item: SpecificationData, index) => {
    const selectedSpec = item.children.find(
      (child) => child.keyName === specKeyList[index]
    );

    return {
      [item.name || item.shortName]:
        selectedSpec?.name || selectedSpec?.shortName,
    };
  });
};

const findAllValidKeys = (
  formValues: any,
  sku: SkuData[],
  specifications: SpecificationData[]
): string[] => {
  if (!formValues || !sku || !specifications) return [];

  const fields = Object.keys(formValues);
  const selectedKeys = Object.values(formValues).filter((item) => item);
  const reducer = (acc: any, curr: any, index: number) => {
    const possibleKeyNames =
      specifications
        .find((item) => item.keyName === curr)
        ?.children.map((item) => item.keyName) || [];
    const selectedKey = selectedKeys[index];
    const selectedKeysWithoutCurr = selectedKeys.filter(
      (item) => item !== selectedKey
    );

    const possibleContains = possibleKeyNames.filter((item) => {
      const possibleValues = [...selectedKeysWithoutCurr, item];
      const containedSkuList = sku.filter((s) =>
        mapand(possibleValues, (value) => s.keyName.includes(value))
      );

      return containedSkuList.length > 0;
    });

    return [...acc, ...possibleContains];
  };

  return fields.reduce(reducer, []);
};

const isFormFull = (formValues: any) => {
  const values = Object.values(formValues);

  return (
    values.filter(
      (value) => value !== "" && value !== undefined && value !== null
    ).length === values.length
  );
};

const findSku = (formValues: any, sku: SkuData[]): SkuData | undefined => {
  const keyNames = Object.entries(formValues);
  const skuKeyName = keyNames
    .map((item) => {
      const [field, value] = item;
      const keyName = `${field}/${value}`;

      return keyName;
    })
    .join(" ");
  const result = sku.find((item) => item.keyName === skuKeyName);

  return result;
};

const SelectSpec = ({ specifications, sku }: SpecFormProps) => {
  const defaultSku = sku.find((item) => item.isDefault);
  const defaultValues = keyNameListToValues(defaultSku);
  const defaultValidList = findAllValidKeys(defaultValues, sku, specifications);
  const [validList, setValidList] = useState<string[]>(defaultValidList);
  const [selectedSku, setSelectedSku] = useState<SkuData | undefined>(
    defaultSku
  );
  const { setSelectedSkuAndSpecData, resetSelectedSkuAndSpecData } =
    useSpuInfo();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const refreshValues = (validList: string[]) => {
    const values = form.getFieldsValue();
    const reducer = (acc: any, curr: any) => {
      const [k, v] = curr;
      if (validList.indexOf(v) === -1) {
        return {
          ...acc,
          [k]: "",
        };
      }
      return { ...acc, [k]: v };
    };

    return Object.entries(values).reduce(reducer, {});
  };

  const handleSpecChange = (_changedValues: any, values: any) => {
    const result = findAllValidKeys(values, sku, specifications);
    const newValues = refreshValues(result);
    setValidList(result);
    form.setFieldsValue(newValues);

    if (isFormFull(newValues)) {
      const newSku = findSku(newValues, sku);
      if (newSku) {
        setSelectedSku(newSku);
        setSelectedSkuAndSpecData(newSku);
      }
    } else {
      setSelectedSku(undefined);
      resetSelectedSkuAndSpecData();
    }
  };

  const handleSpecReset = () => {
    if (defaultSku) {
      setSelectedSku(defaultSku);
      setValidList(defaultValidList);
      setSelectedSkuAndSpecData(defaultSku);
    } else {
      setSelectedSku(undefined);
      setValidList([]);
      resetSelectedSkuAndSpecData();
    }
    form.resetFields();
  };

  return (
    <>
      <Form
        form={form}
        onValuesChange={handleSpecChange}
        initialValues={defaultSku && defaultValues}
        labelCol={{ span: 5 }}
        labelAlign="left"
      >
        {specifications.map((spec) => (
          <Form.Item label={spec.name} key={spec.keyName} name={spec.keyName}>
            <CheckableButtonGroup
              options={spec.children.map((item) => ({
                id: item.id,
                name: item.name || item.shortName,
                key: item.keyName,
              }))}
              validList={validList}
            />
          </Form.Item>
        ))}
      </Form>
      {selectedSku && (
        <FixedFooter>
          <Row gutter={10} align="middle">
            <Col xs={24} sm={24} md={18} lg={20} xl={20} xxl={20}>
              <div style={{ marginBottom: "10px" }}>
                <span style={{ wordBreak: "break-all" }}>
                  {t("spuInfo.specForm.selected")} {selectedSku.sku}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={6} lg={4} xl={4} xxl={4}>
              <Space
                direction="horizontal"
                style={{
                  justifyContent: "end",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Button type="default" onClick={handleSpecReset}>
                  {t("spuInfo.specForm.reset")}
                </Button>
                <PrintPdf buttonType="primary" />
              </Space>
            </Col>
          </Row>
        </FixedFooter>
      )}
    </>
  );
};

export default SelectSpec;
