import { Card } from "antd";
import { useTranslation } from "react-i18next";
import useSpuInfo from "../../../stores/spuInfo";
import { SpuImageAttributeData, SpuTextAttributeData } from "../../../types";
import ImageAttribute from "../components/ImageAttribute";
import TextAttribute from "../components/TextAttribute";

interface TextAttributesProps {
  attributes: SpuTextAttributeData[];
}

const TextAttributes = ({ attributes }: TextAttributesProps) => {
  return (
    <>
      {attributes.map((attr) => (
        <div key={attr.name} className="mb-20">
          <h4>{attr.name}</h4>
          <TextAttribute
            attribute={attr}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              overflow: "auto",
            }}
          />
        </div>
      ))}
    </>
  );
};

interface ImageAttributeProps {
  attributes: SpuImageAttributeData[];
}

const FirstImageAttribute = ({ attributes }: ImageAttributeProps) => {
  if (attributes.length <= 0) return <></>;

  return (
    <div className="mb-20">
      <h4>{attributes[0].name}</h4>
      <ImageAttribute
        attribute={attributes[0]}
        style={{
          maxWidth: 400,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
    </div>
  );
};

const RestImageAttributes = ({ attributes }: ImageAttributeProps) => {
  const { selected, otherImageAttributes } = useSpuInfo();
  const notDefault = selected.attribute !== "default";

  if (attributes.length <= 1) return <></>;

  return (
    <>
      {attributes.slice(1).map((attr, index) => (
        <div key={attr.name} className="mb-20">
          <h4>{attr.name}</h4>
          {notDefault && index === 0 && (
            <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 7 }}>
              default
            </div>
          )}
          <ImageAttribute
            attribute={attr}
            style={{ maxWidth: 400, marginLeft: "auto", marginRight: "auto" }}
          />
          {notDefault && index === 0 && (
            <>
              <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 7 }}>
                {selected.attribute}
              </div>
              <ImageAttribute
                attribute={otherImageAttributes[0]}
                style={{
                  maxWidth: 400,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </>
          )}
        </div>
      ))}
    </>
  );
};

const IntroContent = () => {
  const { t } = useTranslation();
  const { selected, content, otherContent } = useSpuInfo();
  const notDefault = selected.size !== "default";

  return (
    <div className="mb-20">
      <h4>{t("spuInfo.content")}</h4>
      {notDefault && content && (
        <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 7 }}>
          default
        </div>
      )}
      <TextAttribute
        attribute={{ item: content } as SpuTextAttributeData}
        style={{ marginLeft: "auto", marginRight: "auto", overflow: "auto" }}
      />
      {notDefault && otherContent && (
        <>
          <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 7 }}>
            {selected.size}
          </div>
          <TextAttribute
            attribute={{ item: otherContent } as SpuTextAttributeData}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              overflow: "auto",
            }}
          />
        </>
      )}
    </div>
  );
};

const Introduction = () => {
  const { t } = useTranslation();
  const { textAttributes, imageAttributes } = useSpuInfo();

  return (
    <Card title={t("spuInfo.introduction")}>
      <TextAttributes attributes={textAttributes} />
      <FirstImageAttribute attributes={imageAttributes} />
      <IntroContent />
      <RestImageAttributes attributes={imageAttributes} />
    </Card>
  );
};

export default Introduction;
