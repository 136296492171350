import { DownOutlined, UpOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  InputNumber,
  PageHeader,
  Select,
  Space,
} from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BaseLayout from "../../components/BaseLayout";
import CategorySelector from "../../components/CategorySelector";
import { Stock } from "../../types";
import SelectionResult from "./components/SelectionResult";
import { useSearchParams } from "react-router-dom";
import SelectionTable from "./components/SelectionTable";
import { parseSelected } from "../../utils";

const { Option } = Select;
const { useForm } = Form;

const parseStock = (str: string | null): Stock => {
  if (str === null) return "all";
  if (["all", "inStock", "outOfStock"].includes(str)) return str as Stock;
  return "all";
};

const SpuSelection = () => {
  const [expanded, setExpanded] = useState(true);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchRef] = useForm();
  const [selectorRef] = useForm();

  const q = Number(searchParams.get("q")) || undefined;
  const h = Number(searchParams.get("h")) || undefined;
  const toleranceStart = Number(searchParams.get("ts")) || 100;
  const toleranceEnd = Number(searchParams.get("te")) || 120;
  const stock: Stock = parseStock(searchParams.get("stock"));
  const selected: number[] = parseSelected(searchParams.getAll("c"));

  const handleReset = () => {
    searchRef.resetFields();
    selectorRef.resetFields();
    setSearchParams({});
  };

  const handleFormChange = () => {
    searchRef.setFieldsValue({ q, h, toleranceStart, toleranceEnd, stock });
    selectorRef.setFieldsValue({ category: { selected } });
  };

  const handleSubmit = (value: any) => {
    const { q, h, stock, toleranceStart, toleranceEnd } = value;
    const { category } = selectorRef.getFieldsValue();

    if (q && h) {
      setSearchParams({
        q,
        h,
        ts: toleranceStart,
        te: toleranceEnd,
        stock,
        c: category.selected,
      });
    }
  };

  useEffect(() => {
    handleFormChange();
    setExpanded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, h, toleranceStart, toleranceEnd, stock, JSON.stringify(selected)]);

  return (
    <BaseLayout title={t("route.selection")}>
      <PageHeader title={t("route.selection")} style={{ padding: "16px 0" }} />
      <Card bodyStyle={{ paddingBottom: 0 }}>
        <Form layout="inline" form={searchRef} onFinish={handleSubmit}>
          <Form.Item name="q" label="Q" style={{ paddingBottom: "24px" }}>
            <InputNumber addonAfter="m³/h" style={{ width: 130 }} />
          </Form.Item>
          <Form.Item name="h" label="H" style={{ paddingBottom: "24px" }}>
            <InputNumber addonAfter="m" style={{ width: 120 }} />
          </Form.Item>
          <Form.Item
            label={t("spuSelection.tolerance")}
            style={{ paddingBottom: "24px" }}
          >
            <Space direction="horizontal">
              <Form.Item name="toleranceStart" noStyle>
                <InputNumber
                  addonAfter="%"
                  style={{ width: 110 }}
                  min={80}
                  max={100}
                />
              </Form.Item>
              -
              <Form.Item name="toleranceEnd" noStyle>
                <InputNumber
                  addonAfter="%"
                  style={{ width: 110 }}
                  min={100}
                  max={140}
                />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item
            name="stock"
            label={t("spuSelection.stock")}
            style={{ paddingBottom: "24px" }}
          >
            <Select style={{ width: 150 }}>
              <Option value="all">{t("spuSelection.all")}</Option>
              <Option value="inStock">{t("spuSelection.inStock")}</Option>
              <Option value="outOfStock">{t("spuSelection.outOfStock")}</Option>
            </Select>
          </Form.Item>
          <Form.Item style={{ paddingBottom: "24px" }}>
            <Space>
              <Button type="primary" htmlType="submit">
                {t("spuSelection.submit")}
              </Button>
              <Button onClick={handleReset}>{t("spuSelection.clear")}</Button>
              <Button type="link" onClick={() => setExpanded(!expanded)}>
                {expanded ? (
                  <>
                    {t("spuSelection.collapse")} <UpOutlined />
                  </>
                ) : (
                  <>
                    {t("spuSelection.expand")} <DownOutlined />
                  </>
                )}
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <Form
          layout="vertical"
          style={{ display: expanded ? "block" : "none" }}
          form={selectorRef}
          initialValues={{ category: { selected: [] } }}
        >
          <Form.Item name="category" label={t("spuSelection.category")}>
            <CategorySelector />
          </Form.Item>
        </Form>
      </Card>
      <Card style={{ marginTop: 24 }}>
        {q && h ? (
          <SelectionResult
            q={q}
            h={h}
            toleranceStart={toleranceStart}
            toleranceEnd={toleranceEnd}
            stock={stock}
            selected={selected}
          />
        ) : (
          <SelectionTable stock={stock} selected={selected} />
        )}
      </Card>
    </BaseLayout>
  );
};

export default SpuSelection;
