import { Modal, Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import useStorage from "../../../stores/storage";
import useModal from "../../../hooks/useModal";
import useField from "../../../hooks/useField";
import { useTranslation } from "react-i18next";

const NewFolder = () => {
  const { t } = useTranslation();
  const { onChange, setValue, value } = useField("");
  const inputProps = { onChange, value };
  const { createDirectory, fetchCurrentDirectory, fetchFolderTree } =
    useStorage();

  const submitHook = async () => {
    await createDirectory(value);
    await fetchCurrentDirectory();
    await fetchFolderTree();
    setValue("");
  };

  const cancelHook = () => setValue("");

  const { onOk, onCancel, confirmLoading, visible, show, okText, cancelText } =
    useModal(submitHook, cancelHook);
  const modalProps = {
    onOk,
    onCancel,
    confirmLoading,
    visible,
    okText,
    cancelText,
  };

  return (
    <>
      <Button type="default" icon={<PlusOutlined />} onClick={show}>
        {t("fileManager.tool.newFolder.title")}
      </Button>
      <Modal
        title={t("fileManager.tool.newFolder.title")}
        centered
        {...modalProps}
      >
        <Input {...inputProps} />
      </Modal>
    </>
  );
};

export default NewFolder;
