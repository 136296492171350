import React, { useState } from "react";
import { Upload, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../libs/axios";

interface UploadSpuDataProps {
  token: string;
}

const UploadSpuData = ({ token }: UploadSpuDataProps) => {
  const [uploading, setUploading] = useState(false);
  const { t } = useTranslation();

  return (
    <Upload
      name="file"
      action={`${BASE_URL}/spus/points/import`}
      headers={{ authorization: `Bearer ${token}` }}
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      onChange={(info: any) => {
        if (info.file.status !== "uploading") {
          setUploading(false);
        }
        if (info.file.status === "uploading" && !uploading) {
          setUploading(true);
        }
        if (info.file.status === "done") {
          message.success(
            t("spuList.extra.upload.success", {
              import_count: info.file.response.response.import_count,
            })
          );
        }
        if (info.file.status === "error") {
          message.error(t("spuList.extra.upload.error"));
        }
      }}
      showUploadList={false}
      multiple={false}
    >
      <Button type="primary" loading={uploading}>
        {t("spuList.extra.upload.title")}
      </Button>
    </Upload>
  );
};

export default UploadSpuData;
