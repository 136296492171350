import { Button, Card, Empty, PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import BaseLayout from "../components/BaseLayout";

const NotFound = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <BaseLayout title={t("notFound.title")}>
      <PageHeader title={t("notFound.title")} style={{ padding: "16px 0" }}>
        <Card>
          <div
            style={{
              minHeight: "70vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Empty
              description={t("notFound.description", {
                pathname: location.pathname,
              })}
            >
              <Button type="primary">{t("notFound.button.backHome")}</Button>
            </Empty>
          </div>
        </Card>
      </PageHeader>
    </BaseLayout>
  );
};

export default NotFound;
