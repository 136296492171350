import "./AttritubeAtom.css";
import { useEffect } from "react";
import { PageHeader, Card, Spin, Space, Button } from "antd";
import BaseLayout from "../../components/BaseLayout";
import useModel from "../../stores/model";
import { useParams } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import CreateAttribute from "./CreateAttribute";
import EditableText from "../../components/EditableText";
import AttributeChunk from "./AttributeChunk";
import { useTranslation } from "react-i18next";

const Model = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { fetchModel, updateModel, name, setName, loading, attributes } =
    useModel();

  const [onUpdate, updating] = useLoading(updateModel);

  useEffect(() => {
    if (id) fetchModel(parseInt(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return (
      <BaseLayout>
        <PageHeader title="Model" style={{ padding: "16px 0" }} />
        <Card>
          <div
            style={{
              minHeight: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Spin />
          </div>
        </Card>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <PageHeader
        title={<EditableText value={name} onFinish={setName} />}
        style={{ padding: "16px 0" }}
        extra={
          <Space>
            <Button type="primary" onClick={onUpdate} loading={updating}>
              {t("model.submit")}
            </Button>
          </Space>
        }
      />
      <Card>
        {attributes.map((item, index) => (
          <AttributeChunk
            id={item.id || 0}
            key={index}
            rowIndex={index}
            name={item.name}
            type={item.type}
            order={item.order}
            items={item.list || []}
            isLast={index === attributes.length - 1}
          />
        ))}
        <CreateAttribute />
      </Card>
    </BaseLayout>
  );
};

export default Model;
