import axios from "../libs/axios";
import { Name } from "../stores/model";

interface ModelListParams {
  q: string;
  page?: number;
  limit?: number;
}

export interface AttrData {
  id?: number;
  spu_model?: number;
  order_index: number;
  type: 0 | 1;
  name_lang: Name[];
  list: [
    {
      id?: number;
      attr_id?: number;
      order_index: number;
      src: number;
      path: string;
      name_lang: Name[];
    }
  ];
}

export interface ModelData {
  name: string;
  attrArr: AttrData[];
}

const getList = async (params: ModelListParams) =>
  await axios.get("/models", { params });

const getModel = async (id: string) => await axios.get(`/models/${id}`);

const postModel = async (model: ModelData) =>
  await axios.post(`/models`, model);

const putModel = async (id: number, model: ModelData) =>
  await axios.put(`/models/${id}`, model);

const deleteModel = async (id: string) => await axios.delete(`/models/${id}`);

const modelServices = {
  getList,
  getModel,
  postModel,
  putModel,
  deleteModel,
};

export default modelServices;
