import "./AttritubeAtom.css";
import { useEffect } from "react";
import { PageHeader, Card, Space, Button } from "antd";
import BaseLayout from "../../components/BaseLayout";
import useModel from "../../stores/model";
import useLoading from "../../hooks/useLoading";
import CreateAttribute from "./CreateAttribute";
import EditableText from "../../components/EditableText";
import AttributeChunk from "./AttributeChunk";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CreateModel = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { resetAll, createModel, setName, attributes } = useModel();

  const handleSubmit = async () => {
    try {
      const res = await createModel();
      const { id } = res.data.response;
      navigate(`/models/${id}`, { replace: true });
    } catch (error) {
      // TODO: handle error
    }
  };

  const [onUploading, uploading] = useLoading(handleSubmit);

  useEffect(() => {
    resetAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseLayout>
      <PageHeader
        title={<EditableText onFinish={setName} />}
        style={{ padding: "16px 0" }}
        extra={
          <Space>
            <Button type="primary" onClick={onUploading} loading={uploading}>
              {t("model.submit")}
            </Button>
          </Space>
        }
      />
      <Card>
        {attributes.map((item, index) => (
          <AttributeChunk
            id={item.id || 0}
            key={index}
            rowIndex={index}
            name={item.name}
            type={item.type}
            order={item.order}
            items={item.list || []}
            isLast={index === attributes.length - 1}
          />
        ))}
        <CreateAttribute />
      </Card>
    </BaseLayout>
  );
};

export default CreateModel;
