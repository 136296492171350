import { Row, Col } from "antd";
import useStorage from "../../../stores/storage";
import SearchBar from "./SearchBar";
import Rename from "./Rename";
import Move from "./Move";
import PathBar from "./PathBar";
import Delete from "./Delete";
import ToggleLayout from "./ToggleLayout";

const FileToolbox = () => {
  const { selected } = useStorage();

  return (
    <>
      <Row
        justify="space-between"
        style={{ marginBottom: 16, padding: "0 16px" }}
        align="middle"
      >
        <Col>
          <Row gutter={16} justify="start" align="middle">
            <Col>
              <SearchBar />
            </Col>
            <Col>
              <PathBar />
            </Col>
          </Row>
        </Col>
        <Col>
          <span>
            {selected.length > 0 && (
              <>
                <Delete />
                <Move />
              </>
            )}
            {selected.length === 1 && <Rename />}
          </span>
          <ToggleLayout />
        </Col>
      </Row>
    </>
  );
};

export default FileToolbox;
