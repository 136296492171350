import { Button, Form, InputNumber, Tabs } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Dataset } from "../../../types";

interface CustomFormProps {
  dataset: Dataset;
  onSubmit: (values: any) => void;
}

const CustomFormList = ({ name }: { name: string }) => {
  return (
    <Form.List name={name}>
      {(fields) => (
        <>
          {fields.map((field, i) => (
            <Form.Item {...field} key={field.key} label={i + 1}>
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          ))}
        </>
      )}
    </Form.List>
  );
};

const CustomForm = ({ dataset, onSubmit }: CustomFormProps) => {
  const { Q, H, EFF } = dataset;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <Form form={form} initialValues={{ Q, H, EFF }} onFinish={onSubmit}>
      <Tabs animated>
        <Tabs.TabPane tab="Q" key="q" forceRender>
          <CustomFormList name="Q" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="H" key="h" forceRender>
          <CustomFormList name="H" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="EFF" key="eff" forceRender>
          <CustomFormList name="EFF" />
        </Tabs.TabPane>
      </Tabs>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t("spuChart.Modify.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CustomForm;
