import { useState } from "react";

const useDrawer = (
  submitHook?: () => void,
  closeHook?: () => void,
  showHook?: () => void,
  hideHook?: () => void
) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const show = () => {
    if (showHook) showHook();
    setVisible(true);
  };

  const hide = () => {
    if (hideHook) hideHook();
    setVisible(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    if (submitHook) await submitHook();
    setLoading(false);
    hide();
  };

  const onClose = () => {
    if (closeHook) closeHook();
    hide();
  };

  return {
    onSubmit,
    onClose,
    confirmLoading: loading,
    setLoading,
    visible,
    setVisible,
    show,
    hide,
  };
};

export default useDrawer;
