import { Input, Row, Col } from "antd";
import React, { useState } from "react";

interface Name {
  zh?: string;
  en?: string;
  ru?: string;
}

interface InputNameProps {
  value?: Name;
  onChange?: (value: Name) => void;
}

const InputName = ({ value = {}, onChange }: InputNameProps) => {
  const [name, setName] = useState<Name>({
    zh: "",
    en: "",
    ru: "",
  });

  const triggerChange = (changedValue: {
    zh?: string;
    en?: string;
    ru?: string;
  }) => {
    onChange?.({ ...name, ...value, ...changedValue });
  };

  const gernerateHandle = (lang: "zh" | "en" | "ru") => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const newName = e.target.value;

      if (!(lang in value)) {
        setName({ ...name, [lang]: newName });
      }

      triggerChange({ [lang]: newName });
    };
  };
  const onZhChange = gernerateHandle("zh");
  const onEnChange = gernerateHandle("en");
  const onRuChange = gernerateHandle("ru");

  return (
    <>
      <Row align="middle" style={{ marginBottom: 16 }}>
        <Col span={6}>中文</Col>
        <Col span={18}>
          <Input value={value.zh || name.zh} onChange={onZhChange} />
        </Col>
      </Row>
      <Row align="middle" style={{ marginBottom: 16 }}>
        <Col span={6}>English</Col>
        <Col span={18}>
          <Input value={value.en || name.en} onChange={onEnChange} />
        </Col>
      </Row>
      <Row align="middle">
        <Col span={6}>русский</Col>
        <Col span={18}>
          <Input value={value.ru || name.ru} onChange={onRuChange} />
        </Col>
      </Row>
    </>
  );
};

export default InputName;
