import { Button, Popconfirm } from "antd";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useLoading from "../../../hooks/useLoading";
import useSpuPoints from "../../../stores/spuPoints";

const Delete = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { delete: deletePoint } = useSpuPoints();

  const handleDelete = () => {
    if (id) deletePoint(id);
  };

  const [onDelete, deleting] = useLoading(handleDelete);

  return (
    <Popconfirm
      title={t("spuForm.delete.confirm")}
      okText={t("confirm.ok")}
      cancelText={t("confirm.cancel")}
      onConfirm={onDelete}
    >
      <Button type="default" loading={deleting}>
        {t("spuChart.tools.delete")}
      </Button>
    </Popconfirm>
  );
};

export default Delete;
