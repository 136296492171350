import React from "react";
import { Button, Tooltip } from "antd";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import useStorage from "../../../stores/storage";
import { useTranslation } from "react-i18next";

const ToggleLayout = () => {
  const { t } = useTranslation();
  const { view, setView } = useStorage();

  const title =
    view === "list"
      ? t("fileManager.tool.layout.grid")
      : t("fileManager.tool.layout.list");

  return (
    <Tooltip title={title}>
      <Button
        type="text"
        icon={view === "list" ? <AppstoreOutlined /> : <BarsOutlined />}
        onClick={() => (view === "list" ? setView("grid") : setView("list"))}
      />
    </Tooltip>
  );
};

export default ToggleLayout;
