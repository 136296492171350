import { Space } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Custom from "./Custom";
import Delete from "./Delete";
import Edit from "./Edit";
import PrintPdf from "./PrintPdf";
import View from "./View";

const Tools = () => {
  const { xs } = useBreakpoint();
  const toolButtons = [
    <Delete key="delete" />,
    <PrintPdf key="pdf" buttonType="default" />,
    <View key="view" />,
    <Custom key="custom" />,
    <Edit key="edit" />,
  ];

  return <Space>{xs ? toolButtons.reverse() : toolButtons}</Space>;
};

export default Tools;
