import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Tabs,
  Row,
  Col,
  FormInstance,
  ConfigProvider,
} from "antd";
import { useTranslation } from "react-i18next";
import SortableCheckList from "../../../components/SortableCheckList";
import { PdfParameterValues } from "../../../types";
import { langMap } from "../../../i18n";

const { Option } = Select;

interface PdfExportProps {
  formRef: FormInstance<any>;
  initialValues: PdfParameterValues;
}

const PdfExport = ({ formRef, initialValues }: PdfExportProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Form form={formRef} initialValues={initialValues} layout="vertical">
      <Tabs>
        <Tabs.TabPane
          tab={t("spuInfo.pdfexport.content")}
          key="content"
          forceRender
        >
          <Form.Item name="filename" label={t("spuInfo.pdfExport.filename")}>
            <Input />
          </Form.Item>
          <Form.Item name="modules" label={t("spuInfo.pdfExport.modules")}>
            <SortableCheckList />
          </Form.Item>
          <Form.Item label={t("spuInfo.pdfExport.custom")}>
            <Form.List name="custom">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ marginBottom: 8, marginTop: 8 }}
                      align="baseline"
                    >
                      <Form.Item {...restField} name={[name, "key"]} noStyle>
                        <Input placeholder={t("spuInfo.pdfExport.key")} />
                      </Form.Item>
                      <Form.Item {...restField} name={[name, "value"]} noStyle>
                        <Input placeholder={t("spuInfo.pdfExport.value")} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item noStyle>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                      style={{ marginTop: 8 }}
                    ></Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t("spuInfo.pdfExport.company.title")}
          key="companyinfo"
          forceRender
        >
          <Form.Item
            name={["company", "name"]}
            label={t("spuInfo.pdfExport.company.name")}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name={["company", "founder"]}
            label={t("spuInfo.pdfExport.company.founder")}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name={["company", "email"]}
            label={t("spuInfo.pdfExport.company.email")}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            name={["company", "phone"]}
            label={t("spuInfo.pdfExport.company.phone")}
          >
            <Input type="tel" />
          </Form.Item>
          <Form.Item
            name={["company", "date"]}
            label={t("spuInfo.pdfExport.company.date")}
          >
            <ConfigProvider locale={langMap(i18n.language)}>
              <DatePicker format="YYYY/MM/DD" style={{ width: "150px" }} />
            </ConfigProvider>
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t("spuInfo.pdfExport.typography.title")}
          key="typography"
          forceRender
        >
          <Form.Item
            name="paperSize"
            label={t("spuInfo.pdfExport.typography.paperSize")}
          >
            <Select>
              <Option value="A0">A0</Option>
              <Option value="A1">A1</Option>
              <Option value="A2">A2</Option>
              <Option value="A3">A3</Option>
              <Option value="A4">A4</Option>
              <Option value="A5">A5</Option>
            </Select>
          </Form.Item>
          <Form.Item label={t("spuInfo.pdfExport.typography.margin")}>
            <Input.Group>
              <Row style={{ alignItems: "center" }}>
                <Col span={6}>
                  <span>{t("spuInfo.pdfExport.typography.left")}</span>
                </Col>
                <Col span={18}>
                  <Form.Item
                    name={["margin", "top"]}
                    style={{ marginBottom: "16px" }}
                  >
                    <InputNumber addonAfter="mm" />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ alignItems: "center" }}>
                <Col span={6}>
                  <span>{t("spuInfo.pdfExport.typography.right")}</span>
                </Col>
                <Col span={18}>
                  <Form.Item
                    name={["margin", "right"]}
                    style={{ marginBottom: "16px" }}
                  >
                    <InputNumber addonAfter="mm" />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ alignItems: "center" }}>
                <Col span={6}>
                  <span>{t("spuInfo.pdfExport.typography.bottom")}</span>
                </Col>
                <Col span={18}>
                  <Form.Item
                    name={["margin", "bottom"]}
                    style={{ marginBottom: "16px" }}
                  >
                    <InputNumber addonAfter="mm" />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ alignItems: "center" }}>
                <Col span={6}>
                  <span>{t("spuInfo.pdfExport.typography.top")}</span>
                </Col>
                <Col span={18}>
                  <Form.Item
                    name={["margin", "left"]}
                    style={{ marginBottom: "16px" }}
                  >
                    <InputNumber addonAfter="mm" />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
};

export default PdfExport;
