import React, { useEffect, useState } from "react";
import {
  Menu,
  Space,
  Dropdown,
  Button,
  Input,
  PageHeader,
  Form,
  Card,
  Table,
  ConfigProvider,
} from "antd";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DownOutlined,
  UpOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import BaseLayout from "../../components/BaseLayout";
import spuServices from "../../services/spu";
import { useTranslation } from "react-i18next";
import useLoading from "../../hooks/useLoading";
import useAuth from "../../hooks/useAuth";
import { langMap } from "../../i18n";
import { parseSelected } from "../../utils";
import UploadSpuData from "./components/UploadSpuData";
import CategorySelector from "../../components/CategorySelector";

interface SpuState {
  id: number;
  key: number;
  name: string;
  spu: string;
  order: number;
  hasPoints: boolean;
}

type QueryParams = {
  query: string;
};

const SpuList = () => {
  const [expanded, setExpanded] = useState(true);
  const [spuListDataSource, setSpuListDataSource] = useState<SpuState[]>([]);
  const { auth, isAdmin } = useAuth();
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchRef] = Form.useForm();
  const [selectorRef] = Form.useForm();

  let query: string = searchParams.get("query") || "";
  let page: number = Number(searchParams.get("page")) || 1;
  let pageSize: number = Number(searchParams.get("pageSize")) || 20;
  let selected: number[] = parseSelected(searchParams.getAll("c"));
  const [total, setTotal] = useState<number>(0);

  const handleSubmit = (values: QueryParams) => {
    const { query } = values;
    const { category } = selectorRef.getFieldsValue();

    setSearchParams({ query, page: "1", pageSize: "20", c: category.selected });
  };

  const handleReset = () => {
    searchRef.resetFields();
    selectorRef.resetFields();
    setSearchParams({});
  };

  const handleFormChange = () => {
    searchRef.setFieldsValue({ query });
    selectorRef.setFieldsValue({ category: { selected } });
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setSpuListDataSource([]);
    setSearchParams({
      query,
      page: String(page),
      pageSize: pageSize ? String(pageSize) : "20",
    });
  };

  const getSpuList = async () => {
    const result = await spuServices.getList({
      q: query,
      page: page,
      limit: pageSize,
      cat: selected.join(",") || undefined,
    });
    const { totalCount, spus } = result.data.response;

    // update total
    setTotal(totalCount);
    // update data source
    setSpuListDataSource(
      spus.map((spu: any) => {
        return {
          id: spu.id,
          key: spu.id,
          name: spu.spu_name,
          spu: spu.spu,
          order: spu.order_index,
          hasPoints: spu.has_points,
        };
      })
    );
  };

  const [onPageChange, loading] = useLoading(getSpuList);

  useEffect(() => {
    handleFormChange();
    onPageChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, pageSize, JSON.stringify(selected)]);

  const columns = [
    {
      key: "id",
      title: "id",
      dataIndex: "id",
      width: 100,
    },
    {
      key: "name",
      title: t("spuList.columns.name"),
      dataIndex: "name",
      ellipsis: true,
      width: 200,
    },
    {
      key: "spu",
      title: "SPU",
      dataIndex: "spu",
      ellipsis: true,
      width: 200,
    },
    {
      key: "order",
      title: t("spuList.columns.order"),
      dataIndex: "order",
      ellipsis: true,
      width: 100,
    },
    {
      key: "hasPoints",
      title: t("spuList.columns.hasPoints"),
      dataIndex: "hasPoints",
      width: 100,
      align: "center" as const,
      render: (value: any) =>
        value ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#bfbfbf" />
        ),
    },
    {
      key: "action",
      title: t("spuList.columns.action"),
      dataIndex: "action",
      width: 100,
      fixed: "right" as const,
      render: (_: any, record: { key: React.Key }) => {
        const menu = (
          <Menu>
            <Menu.Item key="chart">
              <Link to={`/spus/${String(record.key)}`}>
                {t("spuList.action.chart")}
              </Link>
            </Menu.Item>
            <Menu.Item key="view">{t("spuList.action.view")}</Menu.Item>
            {isAdmin && (
              <>
                <Menu.Item key="edit">{t("spuList.action.edit")}</Menu.Item>
                <Menu.Item key="delete">{t("spuList.action.delete")}</Menu.Item>
              </>
            )}
          </Menu>
        );

        return (
          <Space>
            <Dropdown overlay={menu}>
              <Button type="text" icon={<UnorderedListOutlined />}>
                <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  return (
    <BaseLayout title={t("route.spuList")}>
      <PageHeader
        title={t("spuList.title")}
        style={{ padding: "16px 0" }}
        extra={auth && isAdmin && <UploadSpuData token={auth.token} />}
      />
      <Card bodyStyle={{ paddingBottom: 0 }}>
        <Form layout="inline" onFinish={handleSubmit} form={searchRef}>
          <Form.Item
            // label={t("spuList.search.name.label")}
            name="query"
            style={{ paddingBottom: "24px" }}
          >
            <Input placeholder={t("spuList.search.name.placeholder")} />
          </Form.Item>
          <Form.Item style={{ paddingBottom: "24px" }}>
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t("spuList.search.submit")}
              </Button>
              <Button type="default" onClick={handleReset}>
                {t("spuList.search.reset")}
              </Button>
              <Button type="link" onClick={() => setExpanded(!expanded)}>
                {expanded ? (
                  <>
                    {t("spuSelection.collapse")} <UpOutlined />
                  </>
                ) : (
                  <>
                    {t("spuSelection.expand")} <DownOutlined />
                  </>
                )}
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <Form
          layout="vertical"
          style={{ display: expanded ? "block" : "none" }}
          form={selectorRef}
          initialValues={{ category: { selected: [] } }}
        >
          <Form.Item name="category" label={t("spuSelection.category")}>
            <CategorySelector />
          </Form.Item>
        </Form>
      </Card>
      <Card style={{ marginTop: 24 }}>
        <ConfigProvider locale={langMap(i18n.language)}>
          <Table
            columns={columns}
            bordered={false}
            dataSource={spuListDataSource}
            scroll={{ x: 325 }}
            pagination={{
              current: page,
              pageSize,
              total,
              onChange: handlePageChange,
            }}
            loading={loading}
          />
        </ConfigProvider>
      </Card>
    </BaseLayout>
  );
};

export default SpuList;
