import React from "react";
import { Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { languages } from "../i18n";
import "./LanguageMenu.css";

const LanguageMenu = ({
  style,
  theme,
}: {
  style?: object;
  theme?: "dark" | "light";
}) => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (key: string) => {
    // 语言菜单选择
    const langs = languages.map((lang) => lang.key);
    if (langs.includes(key)) {
      i18n.changeLanguage(key);
      window.location.reload();
    }
  };

  const menu = (
    <Menu
      selectedKeys={[i18n.language]}
      onClick={({ key }) => handleLanguageChange(key)}
      style={style}
      theme={theme}
    >
      {languages.map((lang) => (
        <Menu.Item key={lang.key}>{lang.name}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} arrow>
      <span style={{ cursor: "pointer" }}>{t("chart.language")}</span>
    </Dropdown>
  );
};

export default LanguageMenu;
