import create from "zustand";
import { keyNameListToSpecData } from "../pages/Spu/Description/SelectSpec";
import spuServices from "../services/spu";

interface SpuAttributeData {
  name: string;
  type: number;
  src: number;
  item: string;
}

type SpuTextAttributeData = SpuAttributeData;

interface SpuImageAttributeData extends SpuAttributeData {
  path: string;
  image: string;
  image2x: string;
}

interface SkuData {
  id: number;
  sku: string;
  skuId: string;
  spuId: number;
  key: string;
  keyName: string;
  isDefault: boolean;
}

interface SpuCategory {
  id: number;
  name: string;
  image: string;
  image2x: string;
  content: string;
}

interface SpecificationItem {
  id: number;
  name: string;
  keyName: string;
  shortName: string;
}

interface SpecificationData extends SpecificationItem {
  children: SpecificationItem[];
}

interface SpuCategory {
  id: number;
  name: string;
  image: string;
  image2x: string;
  content: string;
}

interface SelectedLabels {
  size: string;
  attribute: string;
}

interface SelectedSkuAndSpecData {
  sku: string;
  specData: any[];
}

interface SpuInfo {
  id: number;
  spu: string;
  spuName: string;
  image: string;
  image2x: string;
  sku: SkuData[];
  createdAt: string;
  updatedAt: string;
  // 分类
  category: SpuCategory;
  // 属性
  attributes: SpuAttributeData[]; // type == 0
  otherAttributes: SpuAttributeData[];
  // 规格
  specifications: SpecificationData[];
  // 详情
  details: {
    image: string;
    image2x: string;
  };
  // 文本属性
  textAttributes: SpuTextAttributeData[]; // type == 2
  // 图片属性
  imageAttributes: SpuImageAttributeData[]; // type == 1
  otherImageAttributes: SpuImageAttributeData[];
  // 其他
  content: string;
  otherContent: string;
  // 标签
  label: {
    size: string[];
    attribute: string[];
  };
  // 组件状态
  loading: boolean;
  // 选中的标签
  selected: SelectedLabels;
  labelLoading: boolean;
  // 选中的sku
  selectedSkuAndSpecData: SelectedSkuAndSpecData;
}

const parseSku = (sku: any[]): SkuData[] => {
  return sku.map((item: any) => ({
    id: item.id,
    sku: item.sku,
    skuId: item.sku_id,
    spuId: item.spu_id,
    key: item.key,
    keyName: item.key_name,
    isDefault: item.is_default,
  }));
};

const parseCategory = (data: any): SpuCategory => ({
  id: data.info.cat_id,
  name: data.info.cate_name,
  image: data.info.cate_sImg,
  image2x: data.info.cate_bImg,
  content: data.info.cate_content,
});

const parseAttrs =
  (attrs: any[]) => (type: number, convert?: (A: any) => any) =>
    attrs
      .filter((attr) => attr.type === type)
      .map((attr) => ({
        name: attr.attr,
        type: attr.type,
        src: attr.src,
        item: attr.attr_item,
        ...(convert ? convert(attr) : {}),
      }));

const parseSpecifications = (spec: any[]): SpecificationData[] =>
  spec.map((item: any) => ({
    id: item.id,
    name: item.name_lang,
    keyName: item.name,
    shortName: item.short_name,
    children: item.spec_item.map((child: any) => ({
      id: child.id,
      name: child.name_lang,
      keyName: child.name,
      shortName: child.short_name,
    })),
  }));

const parseSpuInfo = (data: any) => {
  // sku 列表
  const sku: SkuData[] = parseSku(data.sku);
  // 分类
  const category: SpuCategory = parseCategory(data);
  // 属性
  const attrs: any[] = data.attr_link;
  const generalParseAttrs = parseAttrs(attrs);
  // 产品属性
  const productAttrs: SpuAttributeData[] = generalParseAttrs(0);
  // 图片属性
  const imageAttributes: SpuImageAttributeData[] = generalParseAttrs(
    1,
    (attr: any) => ({
      path: attr.path,
      image: attr.sImg,
      image2x: attr.bImg,
    })
  );
  // 文本属性
  const textAttributes: SpuTextAttributeData[] = generalParseAttrs(2).filter(
    (attr: any) => attr.item !== ""
  );
  // 规格
  const specifications: SpecificationData[] = parseSpecifications(data.spec);
  // 详情（图片）
  const details = {
    image: data.info.tec_sImg,
    image2x: data.info.tec_bImg,
  };
  // 其他
  const content = data.info.content;
  // 标签
  const label = {
    size: ["default", ...data.label.size_label],
    attribute: ["default", ...data.label.attribute_label],
  };

  return {
    id: data.info.id,
    spu: data.info.spu,
    spuName: data.info.spu_name,
    image: data.info.sImg,
    image2x: data.info.bImg,
    sku,
    createdAt: data.info.create_time,
    updatedAt: data.info.modify_time,
    category,
    attributes: productAttrs,
    specifications,
    details,
    textAttributes,
    imageAttributes,
    content,
    label,
  };
};

interface SpuInfoState extends SpuInfo {
  fetch: (id: string) => void;
  setSelected: (id: string, newSelected: SelectedLabels) => void;
  resetSelected: () => void;
  setSelectedSkuAndSpecData: (sku: SkuData) => void;
  resetSelectedSkuAndSpecData: () => void;
}

export const initialSelected: SelectedLabels = {
  size: "default",
  attribute: "default",
};

export const initialSelectedSkuAndSpecData: SelectedSkuAndSpecData = {
  sku: "",
  specData: [],
};

const initialState: SpuInfo = {
  id: 0,
  spu: "",
  spuName: "",
  image: "",
  image2x: "",
  sku: [],
  createdAt: "",
  updatedAt: "",
  category: {
    id: 0,
    name: "",
    image: "",
    image2x: "",
    content: "",
  },
  attributes: [],
  otherAttributes: [],
  specifications: [],
  details: {
    image: "",
    image2x: "",
  },
  textAttributes: [],
  imageAttributes: [],
  otherImageAttributes: [],
  content: "",
  otherContent: "",
  label: {
    size: [],
    attribute: [],
  },
  loading: false,
  selected: initialSelected,
  labelLoading: false,
  selectedSkuAndSpecData: initialSelectedSkuAndSpecData,
};

const useSpuInfo = create<SpuInfoState>((set, get) => ({
  ...initialState,
  fetch: async (id: string) => {
    try {
      set({ loading: true });
      const res = await spuServices.getInfo(id);
      const data = res.data.response;
      const result = parseSpuInfo(data);

      const { sku, specifications } = result;
      const defaultSku = sku.find((item) => item.isDefault);

      if (defaultSku) {
        const specData = keyNameListToSpecData(defaultSku, specifications);
        set({
          ...initialState,
          ...result,
          selectedSkuAndSpecData: {
            sku: defaultSku.sku,
            specData,
          },
        });
      } else {
        set({
          ...initialState,
          ...result,
        });
      }
    } catch (e) {
      console.error(e);
      set({ ...initialState });
    } finally {
      set({ loading: false });
    }
  },
  setSelected: async (id: string, newSelected: SelectedLabels) => {
    const { size, attribute } = newSelected;
    if (size === "default" && attribute === "default") return;

    try {
      set({ labelLoading: true });
      const res = await spuServices.getSizeAndAttrByLabel(id, size, attribute);
      const data = res.data.response;
      let newInfo = {};

      // 尺寸
      const { size: newSize } = data;
      if (newSize) {
        newInfo = {
          otherContent: newSize,
        };
      }

      // 属性
      const { attribute: newAttribute } = data;
      if (newAttribute) {
        const newAttributes = newAttribute.map((item: any) => ({
          name: item.attr,
          item: item.attr_item,
          src: 0,
          type: 0,
        }));
        newInfo = {
          ...newInfo,
          otherAttributes: newAttributes,
        };
      }

      // 图片
      const { size_image } = data;
      if (size_image) {
        const images = [
          {
            path: size_image,
            image: size_image,
            image2x: size_image,
          },
        ];
        newInfo = {
          ...newInfo,
          otherImageAttributes: images,
        };
      }

      set({ selected: newSelected, ...newInfo });
    } catch (e) {
      console.error(e);
    } finally {
      set({ labelLoading: false });
    }
  },
  resetSelected: () => set({ selected: initialSelected }),
  setSelectedSkuAndSpecData: (sku: SkuData) =>
    set((state) => ({
      selectedSkuAndSpecData: {
        sku: sku.sku,
        specData: keyNameListToSpecData(sku, state.specifications),
      },
    })),
  resetSelectedSkuAndSpecData: () =>
    set({ selectedSkuAndSpecData: initialSelectedSkuAndSpecData }),
}));

export default useSpuInfo;
