import { useState } from "react";
import { Button, Drawer, Tooltip } from "antd";
import { DeliveredProcedureOutlined } from "@ant-design/icons";
import FileTree from "../FileTree";
import useStorage from "../../../stores/storage";
import storageServices from "../../../services/storage";
import { useTranslation } from "react-i18next";

const Move = () => {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const { t } = useTranslation();
  const [id, setId] = useState(1);
  const [loading, setLoading] = useState(false);
  const { selected, fetchCurrentDirectory, fetchFolderTree } = useStorage();
  const handleSelect = (id: number) => setId(id);

  const handleSubmit = async () => {
    setLoading(true);
    await storageServices.moveObjects(selected, id);
    await fetchCurrentDirectory();
    await fetchFolderTree();
    setLoading(false);
  };

  return (
    <>
      <Tooltip title={t("fileManager.tool.move.title")}>
        <Button
          type="text"
          icon={<DeliveredProcedureOutlined />}
          onClick={show}
        />
      </Tooltip>
      <Drawer
        title={t("fileManager.tool.move.title")}
        visible={visible}
        extra={
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            {t("fileManager.tool.move.select")}
          </Button>
        }
        onClose={hide}
        closable
        destroyOnClose
      >
        <FileTree onChange={handleSelect} />
      </Drawer>
    </>
  );
};

export default Move;
