import React, { useState } from "react";
import { Button, Row, Col, Form, Space, Drawer } from "antd";
import { PdfParameterValues, SkuData, SpecificationData } from "../../types";
import FixedFooter from "../../components/FixedFooter";
import CheckableButtonGroup from "./components/CheckableButtonGroup";
import { mapand } from "../../utils";
import PdfExport from "./components/PdfExport";
import spuServices from "../../services/spu";
import useLoading from "../../hooks/useLoading";
import useChartImage from "../../hooks/useChartImage";
import { useTranslation } from "react-i18next";

interface SpecFormProps {
  specifications: SpecificationData[];
  pdfParameter: PdfParameterValues;
  sku: SkuData[];
  id: string;
  spu: string;
  onSpecChange: (sku: string, specData: any) => void;
}

const keyNameListToValues = (sku: SkuData | undefined) => {
  if (!sku) return {};

  const specKeyList = sku.keyName.split(" ");
  const reducer = (acc: any, curr: any) => {
    const [key, value] = curr.split("/");

    return {
      ...acc,
      [key]: value,
    };
  };

  return specKeyList.reduce(reducer, {});
};

const keyNameListToSpecData = (
  sku: SkuData | undefined,
  spec: SpecificationData[]
) => {
  if (!sku || !spec) return [];

  const specKeyList = sku.keyName.split(" ").map((item) => item.split("/")[1]);

  return spec.map((item: SpecificationData, index) => {
    const selectedSpec = item.children.find(
      (child) => child.keyName === specKeyList[index]
    );

    return {
      [item.name || item.shortName]:
        selectedSpec?.name || selectedSpec?.shortName,
    };
  });
};

const findAllValidKeys = (
  formValues: any,
  sku: SkuData[],
  specifications: SpecificationData[]
): string[] => {
  if (!formValues || !sku || !specifications) return [];

  const fields = Object.keys(formValues);
  const selectedKeys = Object.values(formValues).filter((item) => item);
  const reducer = (acc: any, curr: any, index: number) => {
    const possibleKeyNames =
      specifications
        .find((item) => item.keyName === curr)
        ?.children.map((item) => item.keyName) || [];
    const selectedKey = selectedKeys[index];
    const selectedKeysWithoutCurr = selectedKeys.filter(
      (item) => item !== selectedKey
    );

    const possibleContains = possibleKeyNames.filter((item) => {
      const possibleValues = [...selectedKeysWithoutCurr, item];
      const containedSkuList = sku.filter((s) =>
        mapand(possibleValues, (value) => s.keyName.includes(value))
      );

      return containedSkuList.length > 0;
    });

    return [...acc, ...possibleContains];
  };

  return fields.reduce(reducer, []);
};

const isFormFull = (formValues: any) => {
  const values = Object.values(formValues);

  return (
    values.filter(
      (value) => value !== "" && value !== undefined && value !== null
    ).length === values.length
  );
};

const findSku = (formValues: any, sku: SkuData[]): SkuData | undefined => {
  const keyNames = Object.entries(formValues);
  const skuKeyName = keyNames
    .map((item) => {
      const [field, value] = item;
      const keyName = `${field}/${value}`;

      return keyName;
    })
    .join(" ");
  const result = sku.find((item) => item.keyName === skuKeyName);

  return result;
};

const SpecForm = ({
  specifications,
  sku,
  pdfParameter,
  id,
  spu,
  onSpecChange,
}: SpecFormProps) => {
  const defaultSku = sku.find((item) => item.isDefault);
  const defaultValues = keyNameListToValues(defaultSku);
  const defaultValidList = findAllValidKeys(defaultValues, sku, specifications);
  const [validList, setValidList] = useState<string[]>(defaultValidList);
  const [selectedSku, setSelectedSku] = useState<SkuData | undefined>(
    defaultSku
  );
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const refreshValues = (validList: string[]) => {
    const values = form.getFieldsValue();
    const reducer = (acc: any, curr: any) => {
      const [k, v] = curr;
      if (validList.indexOf(v) === -1) {
        return {
          ...acc,
          [k]: "",
        };
      }
      return { ...acc, [k]: v };
    };

    return Object.entries(values).reduce(reducer, {});
  };

  const handleChange = () => {
    const formValues = form.getFieldsValue();
    const result = findAllValidKeys(formValues, sku, specifications);
    const newValues = refreshValues(result);
    setValidList(result);
    form.setFieldsValue(newValues);

    if (isFormFull(newValues)) {
      const newSku = findSku(newValues, sku);
      if (newSku) {
        setSelectedSku(newSku);
        const specData = keyNameListToSpecData(newSku, specifications);
        onSpecChange(newSku.sku, specData);
      }
    } else {
      setSelectedSku(undefined);
    }
  };

  const handleSpecReset = () => {
    if (defaultSku) {
      setSelectedSku(defaultSku);
      setValidList(defaultValidList);
    }
    form.resetFields();
  };

  const [visible, setVisible] = useState(false);
  const { getAllImages } = useChartImage();
  const [pdfExportRef] = Form.useForm();
  const handleOk = async () => {
    if (!id || !selectedSku) return;

    const values = pdfExportRef.getFieldsValue();
    const images = getAllImages();
    const date = values.company.date
      ? values.company.date.format("YYYY-MM-DD")
      : "";
    const specData = keyNameListToSpecData(selectedSku, specifications);
    const params = {
      pageSet: {
        format: values.paperSize,
        margin_left: values.margin.left,
        margin_right: values.margin.right,
        margin_top: values.margin.top,
        margin_bottom: values.margin.bottom,
      },
      companyHead: {
        name: values.company.name,
        founder: values.company.founder,
        mobile: values.company.phone,
        email: values.company.email,
        date,
      },
      modelView: values.modules.map((item: any) => item.key),
      pdfData: {
        text: values.custom
          ? values.custom.map((item: any) => ({
              name: item.key,
              value: item.value,
            }))
          : [],
        images,
      },
      specData,
      sku: selectedSku.sku,
    };

    const res = await spuServices.postPdfExport(id, params);
    const { data } = res;
    const url = window.URL.createObjectURL(
      new Blob([data], { type: "application/pdf" })
    );

    const download = document.createElement("a");
    download.href = url;
    download.download = `${values.filename || spu}.pdf`;
    download.click();
    setVisible(false);
    pdfExportRef.resetFields();
  };
  const [onPdfExportOk, pdfExporting] = useLoading(handleOk);
  const handlePdfExportReset = () => pdfExportRef.resetFields();
  const handleCancel = () => setVisible(false);
  const showModal = () => setVisible(true);

  return (
    <>
      <Form
        form={form}
        onValuesChange={handleChange}
        initialValues={defaultSku && defaultValues}
        labelCol={{ span: 5 }}
        labelAlign="left"
      >
        {specifications.map((spec) => (
          <Form.Item label={spec.name} key={spec.keyName} name={spec.keyName}>
            <CheckableButtonGroup
              options={spec.children.map((item) => ({
                id: item.id,
                name: item.name || item.shortName,
                key: item.keyName,
              }))}
              validList={validList}
            />
          </Form.Item>
        ))}
      </Form>
      {selectedSku && (
        <FixedFooter>
          <Row gutter={10} align="middle">
            <Col xs={24} sm={24} md={18} lg={20} xl={20} xxl={20}>
              <div style={{ marginBottom: "10px" }}>
                <span style={{ wordBreak: "break-all" }}>
                  {t("spuInfo.specForm.selected")} {selectedSku.sku}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={6} lg={4} xl={4} xxl={4}>
              <Space
                direction="horizontal"
                style={{
                  justifyContent: "end",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Button type="default" onClick={handleSpecReset}>
                  {t("spuInfo.specForm.reset")}
                </Button>
                <Button type="primary" onClick={showModal}>
                  {t("spuInfo.specForm.exportPdf")}
                </Button>
              </Space>
            </Col>
          </Row>
        </FixedFooter>
      )}
      <Drawer
        title={t("spuInfo.specForm.exportPdf")}
        onClose={handleCancel}
        visible={visible}
        width={359}
        extra={
          <Space>
            <Button onClick={handlePdfExportReset}>
              {t("spuInfo.specForm.reset")}
            </Button>
            <Button
              type="primary"
              onClick={onPdfExportOk}
              loading={pdfExporting}
            >
              {t("spuInfo.specForm.submit")}
            </Button>
          </Space>
        }
      >
        <PdfExport formRef={pdfExportRef} initialValues={pdfParameter} />
      </Drawer>
    </>
  );
};

export default SpecForm;
