import { Input } from "antd";
import { useTranslation } from "react-i18next";
import useStorage from "../../../stores/storage";

const { Search } = Input;

const SearchBar = () => {
  const { t } = useTranslation();
  const { search, loading } = useStorage();

  const handleSubmit = async (value: any) => {
    if (!value) return;
    await search(value);
  };

  return (
    <Search
      style={{ width: 256 }}
      placeholder={t("fileManager.tool.search.placeholder")}
      allowClear
      onSearch={handleSubmit}
      loading={loading}
    />
  );
};

export default SearchBar;
