import { Table, Button } from "antd";
import { FolderOutlined, FileImageFilled } from "@ant-design/icons";
import useStorage from "../../stores/storage";
import { useTranslation } from "react-i18next";

export interface DirFileComponentProps {
  name: string;
  type: "dir" | "file";
  path: string;
  handleClickDir: () => void;
}

const DirFileComponent = ({
  name,
  type,
  path,
  handleClickDir,
}: DirFileComponentProps) => {
  if (type === "dir") {
    return (
      <div>
        <span>
          <FolderOutlined style={{ fontSize: 16 }} />
        </span>
        <Button
          type="link"
          style={{ color: "black" }}
          onClick={handleClickDir}
        >{`${name}/`}</Button>
      </div>
    );
  }

  return (
    <div>
      <span>
        <FileImageFilled style={{ fontSize: 16 }} />
      </span>
      <span style={{ marginLeft: 16 }}>{`${name}`}</span>
    </div>
  );
};

const FileList = () => {
  const { t } = useTranslation();
  const { fileList, loading, setCurrentRef, selected, setSelected } =
    useStorage();

  const columns = [
    {
      title: t("fileManager.list.table.name"),
      dataIndex: "name",
      ellpsis: true,
      render: (name: string, record: any) => {
        return (
          <DirFileComponent
            name={name}
            type={record.type}
            path={record.path}
            handleClickDir={() => setCurrentRef(record.id)}
          />
        );
      },
    },
    {
      title: t("fileManager.list.table.createdAt"),
      dataIndex: "createdAt",
      width: 200,
    },
  ];

  return (
    <Table
      loading={loading}
      rowKey="id"
      columns={columns}
      dataSource={fileList}
      size="middle"
      scroll={{ x: 600 }}
      pagination={false}
      bordered={false}
      rowSelection={{
        onChange: (selectedRowsKeys) =>
          setSelected(selectedRowsKeys as number[]),
        selectedRowKeys: selected,
      }}
    />
  );
};

export default FileList;
