import React from "react";
import { SpuTextAttributeData } from "../../../types";

interface TextAttributeProps {
  attribute: SpuTextAttributeData;
  style?: React.CSSProperties;
}

const TextAttribute = ({ attribute, style }: TextAttributeProps) => {
  return (
    <div
      className="description"
      dangerouslySetInnerHTML={{
        __html: attribute.item,
      }}
      style={style}
    ></div>
  );
};

export default TextAttribute;
